import {CalendarRawDate} from '@mp/common/types';
import {toDate} from '@mp/common/utils/converters';
import {CalendarDate, CalendarEventType, CalendarRepeatType} from '../../types';

export function resolveCalendarDates(rawCalendarData: Array<CalendarRawDate>): Array<CalendarDate> {
    return (
        rawCalendarData?.map((raw: CalendarRawDate): CalendarDate => {
            const date: Date = toDate(raw.date);
            const dateTo: Date = toDate(raw.dateTo);
            const repeat: CalendarRepeatType = raw.repeat as CalendarRepeatType;

            return {
                ...raw,
                type: raw.type as CalendarEventType,
                originDate: date,
                date,
                dateTo,
                timeFrom: raw.timeFrom,
                timeTo: raw.timeTo,
                doneDate: toDate(raw.doneDate),
                isRemovable: resolveIsRemovable(repeat, raw.everyWeek),
                repeat
            };
        }) ?? []
    );
}

function resolveIsRemovable(repeatType: CalendarRepeatType, everyWeek: boolean): boolean {
    switch (repeatType) {
        case CalendarRepeatType.NONE:
            return !everyWeek;
        default:
            return false;
    }
}
