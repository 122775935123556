import {InputData} from '@mp/common/components/form-input/types';
import {getSectionOptions} from '../services/costsEstimateItems.service';
import {CostEstimateItem, CostEstimateSection} from '../types';
import {getDescriptionByKey} from './dictionary';

export function getSectionInputsData(item?: CostEstimateSection): Array<InputData<CostEstimateSection>> {
    const {id, name, costEstimateId, estimatedCost} = item ?? {};

    return [
        {id: 'id', displayName: '', type: 'hidden', defaultValue: id},
        {id: 'costEstimateId', displayName: '', type: 'hidden', defaultValue: costEstimateId},
        {id: 'name', displayName: 'Nazwa sekcji', type: 'text', defaultValue: name, validation: {minLength: 2}},
        {
            id: 'estimatedCost',
            displayName: 'Szacowany koszt brutto',
            type: 'text-number',
            defaultValue: estimatedCost,
            validation: {minLength: 1}
        }
    ];
}

export function getItemInputsData(item?: CostEstimateItem): Array<InputData<CostEstimateItem>> {
    const {id, name, date, symbol, costEstimateSectionId, price, pricePaid, link, img, costRefund} = item ?? {};
    return [
        {id: 'id', displayName: '', type: 'hidden', defaultValue: id},
        {
            id: 'costEstimateSectionId',
            displayName: 'Sekcja',
            type: 'select-number',
            selectOptions: getSectionOptions(),
            defaultValue: costEstimateSectionId
        },
        {id: 'name', displayName: 'Nazwa', type: 'text', defaultValue: name, validation: {minLength: 2}},
        {id: 'symbol', displayName: 'Kod/symbol produktu', type: 'text', defaultValue: symbol},
        {id: 'date', displayName: getDescriptionByKey('date'), type: 'date', defaultValue: date},
        {id: 'price', displayName: getDescriptionByKey('price'), type: 'text-number', defaultValue: price, validation: {minLength: 1}},
        {id: 'pricePaid', displayName: getDescriptionByKey('pricePaid'), type: 'text-number', defaultValue: pricePaid},
        {id: 'costRefund', displayName: getDescriptionByKey('costRefund'), type: 'text-number', defaultValue: costRefund},
        {id: 'img', displayName: getDescriptionByKey('img'), type: 'text', defaultValue: img},
        {id: 'link', displayName: 'Link', type: 'text', defaultValue: link}
    ];
}
