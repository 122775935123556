import {Logger} from '@mp/common/logger';
import {searchMoviesService} from '@mp/filmweb/services/searchMovies.service';
import {SearchMovieResult} from '@mp/filmweb/types';
import {updatePosterService} from '../../services/updatePoster.service';
import {Movie} from '../inCinemaTab/types';

export function onImageError(movie: Movie): void {
    const {id, fid, titleEn, year} = movie;
    Logger.warn(`Could not load image for: ${id}`);

    searchMoviesService(`${titleEn} ${year}`, 4, false).then((moviesResult) => {
        const foundItem: SearchMovieResult = moviesResult.find((item: SearchMovieResult) => item.fid === fid);
        if (foundItem) {
            updatePosterService({id, poster: foundItem.info.posterPath}).then(({success}) => {
                if (success) {
                    Logger.info(`Successfully updated poster:"${foundItem.info.posterPath}" for fid:"${foundItem.fid}"`);
                }
            });
        } else {
            Logger.error(`Could not find image for: ${id}`);
        }
    });
}
