import {useContext, useEffect} from 'react';
import {CommonContext} from '../context';

export function useHideCalendarNearDates(): void {
    const {context, setContext} = useContext(CommonContext);

    useEffect(() => {
        setContext({...context, showCalendarNearDates: false});

        return () => {
            setContext({...context, showCalendarNearDates: true});
        };
    }, []);
}
