import {FormsModal} from '@mp/common/modals/FormsModal';
import {convertToCurrency} from '@mp/common/utils/number';
import React, {useState} from 'react';
import {deleteExpenseService, updateExpenseService} from '../services';
import {t} from '../t.i18n';
import {Expense, ExpenseCategory, ExpensesByDate} from '../types';
import {resolveExpensesByDate} from '../utils';
import {getInputsData} from '../utils/getInputsData';
import {getNameByTypeId} from '../utils/getNameByTypeId';

export function MonthExpensesList(props: {
    expenses: Array<Expense>;
    expenseCategories: Array<ExpenseCategory>;
    onItemChange: () => void;
}): JSX.Element {
    const expensesByDate: Array<ExpensesByDate> = resolveExpensesByDate(props.expenses);
    const [expenseToEdit, setExpenseToEdit] = useState<Expense>(null);
    return (
        <div className="month-expenses-list">
            {expensesByDate.map((d) => (
                <div key={d.date}>
                    <div className="month-expenses-list-date">{d.date}</div>
                    <div>
                        {d.expenses.map((ex) => (
                            <div key={ex.id} className="month-expenses-list-row" onDoubleClick={() => setExpenseToEdit(ex)}>
                                <div>{getNameByTypeId(ex.typeId, props.expenseCategories)}</div>
                                {convertToCurrency(ex.value, true)}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            {expenseToEdit && (
                <FormsModal<Expense>
                    title={t.editWindowTitle}
                    mode="update"
                    inputs={getInputsData(expenseToEdit, props.expenseCategories)}
                    handleClose={() => setExpenseToEdit(null)}
                    onSendForm={(object) => updateExpenseService(object).then(({success}) => onServiceCompleted(success))}
                    onDeleteClick={(id) => deleteExpenseService(id).then(({success}) => onServiceCompleted(success))}
                />
            )}
        </div>
    );

    function onServiceCompleted(success: boolean): void {
        if (success) {
            setExpenseToEdit(null);
            props.onItemChange();
        }
    }
}
