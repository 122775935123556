import {minDigits} from '@mp/common/utils/number';
import {isEmptyString} from '@mp/common/utils/string';
import {EPISODE_TEMPLATE, SEASON_TEMPLATE, Series} from '../types';

export function openNextEpisode(serial: Series) {
    window.open(getLink(serial));
}

function getLink(serial: Series) {
    const {episode, season, link, name} = serial;
    const nextEpisode = episode + 1;
    const SE: string = `S${minDigits(season)}E${minDigits(nextEpisode)}`;
    if (isEmptyString(link?.trim())) {
        return `https://www.google.pl/search?q=${name} ${SE}`;
    }

    return link
        .replace(SEASON_TEMPLATE, season + '')
        .replace(EPISODE_TEMPLATE, nextEpisode + '')
        .replace('{so}', SE);
}
