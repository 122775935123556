import {service} from '@mp/common/api';
import {UserData} from '@mp/common/types';
import {AxiosResponse} from 'axios';
import {setAppSettings, setUserType} from '../../../global';

export function getUserDataService(): Promise<UserData> {
    return service.get('user.getUserData', {prefix: 'comp'}).then((response: AxiosResponse) => {
        const userData: UserData = response.data;
        setUserType(userData.role);
        setAppSettings(userData.settings);
        return userData;
    });
}
