import {service, ServiceResponseWithError} from '@mp/common/api';
import {toLowerCaseAndReplacePolishChars} from '@mp/common/utils/string';
import {AxiosResponse} from 'axios';
import {ShoppingItem} from '../types';

interface ShoppingItemDTO {
    id: string;
    name: string;
    category: string;
}

export function loadItemsService(): Promise<ServiceResponseWithError<Array<ShoppingItem>>> {
    return service.get<Array<ShoppingItemDTO>>('shoppingList.loadItems').then((response: AxiosResponse<Array<ShoppingItemDTO>>) => ({
        data: resolveShoppingItems(response.data)
    }));
}

function resolveShoppingItems(rawItems: Array<ShoppingItemDTO>): Array<ShoppingItem> {
    return rawItems.map((item) => ({
        id: Number(item.id),
        name: item.name,
        category: item.category,
        searchName: toLowerCaseAndReplacePolishChars(item.name)
    }));
}
