import {FormInputRequiredParams} from '@mp/common/types';
import React from 'react';
import {MP_MULTI_SELECT} from '../consts';
import {MultiOption} from '../types';
import {MultiOptionComponent} from './MultiOptionComponent';

export interface MultiSelectProps extends FormInputRequiredParams {
    defaultValues: Array<string>;
    options: Array<MultiOption>;
}

export class MultiSelect extends React.Component<MultiSelectProps> {
    private valuesMap: Set<string> = new Set<string>();

    constructor(props: MultiSelectProps) {
        super(props);

        if (props.disabled != null) {
            throw new Error('not implemented');
        }

        props.defaultValues?.forEach((value) => this.valuesMap.add(value));

        this.state = {};
    }

    public getValues(): Array<string> {
        return Array.from(this.valuesMap.values());
    }

    public render() {
        const {options, defaultValues} = this.props;

        if (options == null || defaultValues == null) {
            return <div>Error</div>;
        }

        return (
            <div className={MP_MULTI_SELECT}>
                {options.map((o) => (
                    <MultiOptionComponent
                        key={o.value}
                        option={o}
                        isSelected={defaultValues.map((x) => x.toString()).includes(o.value)}
                        onClick={() => this.onOptionClick(o)}
                    />
                ))}
            </div>
        );
    }

    private onOptionClick(option: MultiOption): void {
        const {value} = option;
        if (this.valuesMap.has(value)) {
            this.valuesMap.delete(value);
        } else {
            this.valuesMap.add(value);
        }
    }
}
