import {lastElement} from '@mp/common/utils/array';
import classNames from 'classnames';
import React from 'react';
import {MAX_DISPLAYED_PLAYERS_SCORES} from '../consts';
import {DartScoreHistory, Round} from '../types';
import {PlayerRounds, resolvePlayerRounds} from '../utils/resolvePlayerRounds';

interface DartsScoreBoardProps {
    playersIds: Array<string>;
    selectedPlayerId: string;
    history: DartScoreHistory;
    onVictory: () => void;
}

export function DartsScoreBoard(props: DartsScoreBoardProps): JSX.Element {
    const {playersIds, history, selectedPlayerId} = props;
    const playerToRounds: Map<string, PlayerRounds> = new Map<string, PlayerRounds>();
    const isManyPlayers: boolean = playersIds.length > MAX_DISPLAYED_PLAYERS_SCORES;
    const playerIdsOnBoard: Array<string> = isManyPlayers ? playersIds.filter((id) => id === selectedPlayerId) : playersIds;

    playersIds.forEach((playerId) => {
        const playerHistory: DartScoreHistory = history.filter(({id}) => id === playerId);
        playerToRounds.set(playerId, resolvePlayerRounds(playerHistory, playerId === selectedPlayerId));
    });

    function resolveGridTemplateColumns(_playerIds: Array<string>): string {
        return `repeat(auto-fill, ${Math.floor(100 / _playerIds.length)}%)`;
    }

    function resolveLastScore(rounds: Array<Round>): string {
        const lastRound: Round = lastElement(rounds);
        if (lastRound) {
            const {scoreDisplay1: sc1, scoreDisplay2: sc2, scoreDisplay3: sc3} = lastRound;
            const scores: Array<string> = [sc1, sc2, sc3];
            return `[${scores.map((s) => s || 'X').join(', ')}]`;
        }
        return '';
    }

    return (
        <>
            <div className="darts-score-board-title" style={{gridTemplateColumns: resolveGridTemplateColumns(playersIds)}}>
                {playersIds.map((id) => {
                    const {pointsLeft, isSelectedPlayer, rounds} = playerToRounds.get(id);
                    const isVictory: boolean = pointsLeft === 0;
                    return (
                        <div key={id} className={classNames('player', {'player-selected': isSelectedPlayer})}>
                            <div className="player-name">{id}</div>
                            <div className="player-points">{isVictory ? 'Zwycięzca!' : pointsLeft}</div>
                            {isManyPlayers && <div className="player-last-score">{resolveLastScore(rounds)}</div>}
                        </div>
                    );
                })}
            </div>
            <div className="darts-score-board" style={{gridTemplateColumns: resolveGridTemplateColumns(playerIdsOnBoard)}}>
                {playerIdsOnBoard.map((id) => (
                    <SingleScoreBoard
                        key={id}
                        playerId={id}
                        playersToRounds={playerToRounds}
                        isSelectedPlayer={id === selectedPlayerId}
                        onVictory={props.onVictory}
                    />
                ))}
            </div>
        </>
    );
}

function SingleScoreBoard({
    playerId,
    playersToRounds,
    isSelectedPlayer,
    onVictory
}: {
    playerId: string;
    playersToRounds: Map<string, PlayerRounds>;
    isSelectedPlayer: boolean;
    onVictory: () => void;
}): JSX.Element {
    const {rounds, pointsLeft} = playersToRounds.get(playerId);

    const isVictory: boolean = pointsLeft === 0;
    if (isVictory) {
        onVictory();
    }

    return (
        <div className={classNames('player', {'player-selected': isSelectedPlayer})}>
            <div>
                {rounds.map((round, index) => (
                    <div key={index} className={classNames('score-row', {failed: round.isFailed})}>
                        <div>{round.scoreDisplay1}</div>
                        <div>{round.scoreDisplay2}</div>
                        <div>{round.scoreDisplay3}</div>
                        <div>{round.points}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
