import {useBooleanParams, useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {isEmpty} from '@mp/common/utils/array';
import {ID_PARAM, IS_EDIT_IMAGE_PARAM, IS_EDIT_PARAM} from '@mp/route';
import React, {useEffect, useState} from 'react';
import {Loading} from '../../../components/loading';
import {loadItemsService as loadShoppingItemsService, loadShoppingListItemsService} from '../../shopping-list/services';
import {ShoppingItem, ShoppingListItem} from '../../shopping-list/types';
import {RecipesPageContext} from '../context/RecipesPage.context';
import {loadIngredientsService} from '../services';
import {loadRecipesService} from '../services/recipe.service';
import {Ingredient, Recipe, RecipeCategory} from '../types';
import {RecipeEditImagePage} from './RecipeEditImagePage';
import {RecipeEditPage} from './RecipeEditPage';
import {RecipesGrid} from './RecipesGrid';
import {RecipeViewPage} from './RecipeViewPage';
import {sortIngredients} from './utils/sortIngredients';

export function RecipesPage(): JSX.Element {
    const [allRecipeCategories, setAllRecipeCategories] = useState<Array<RecipeCategory>>();
    const [recipes, setRecipes] = useState<Array<Recipe>>();
    const [ingredients, setIngredients] = useState<Array<Ingredient>>();
    const [shoppingItems, setShoppingItems] = useState<Array<ShoppingItem>>();
    const [shoppingList, setShoppingList] = useState<Array<ShoppingListItem>>();

    useEffect(() => {
        loadDataService();
    }, []);

    return (
        <RecipesPageContext.Provider value={{shoppingItems, shoppingList, reloadData: loadDataService}}>
            <PrvRecipesPage allRecipeCategories={allRecipeCategories} recipes={recipes} ingredients={ingredients} />
        </RecipesPageContext.Provider>
    );

    function loadDataService(): void {
        Promise.all([loadRecipesService(), loadIngredientsService(), loadShoppingItemsService(), loadShoppingListItemsService()]).then(
            ([response, _ingredients, _shoppingItems, _shoppingList]) => {
                setAllRecipeCategories(response.categories);
                setRecipes(response.recipes);
                setIngredients(_ingredients);
                setShoppingItems(_shoppingItems.data);
                setShoppingList(_shoppingList.data.commonListItems);
            }
        );
    }
}

interface RecipesPageProps {
    allRecipeCategories: Array<RecipeCategory>;
    recipes: Array<Recipe>;
    ingredients: Array<Ingredient>;
}

function PrvRecipesPage({allRecipeCategories, recipes, ingredients}: RecipesPageProps): JSX.Element {
    const selectedRecipeId = useNumberQueryParams(ID_PARAM);
    const isEdit = useBooleanParams(IS_EDIT_PARAM);
    const isEditImage = useBooleanParams(IS_EDIT_IMAGE_PARAM);

    if (isEmpty(recipes) || isEmpty(ingredients) || isEmpty(allRecipeCategories)) {
        return <Loading />;
    }

    const selectedRecipe: Recipe = recipes.find(({id}) => id === selectedRecipeId);

    if (selectedRecipe) {
        const recipeIngredients: Array<Ingredient> = sortIngredients(ingredients.filter(({recipeId}) => recipeId === selectedRecipe.id));

        if (isEdit) {
            return <RecipeEditPage recipe={selectedRecipe} ingredients={recipeIngredients} categories={allRecipeCategories} />;
        }
        if (isEditImage) {
            return <RecipeEditImagePage recipeId={selectedRecipe.id} imgSrc={selectedRecipe.image} />;
        }
        return <RecipeViewPage recipe={selectedRecipe} ingredients={recipeIngredients} />;
    }

    return <RecipesGrid recipes={recipes} recipeCategories={allRecipeCategories} />;
}
