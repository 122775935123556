import {InputData} from '@mp/common/components/form-input/types';
import {t} from '../i18n';
import {PaymentRow} from '../types';

export function getEditInputsData(selectedRow: PaymentRow): Array<InputData<PaymentRow>> {
    const {id, dateString, totalCost, energy, hotWater, coldWater, energyCost, coldWaterCost, heat} = selectedRow;
    const cost: string = `${t.cost.toLowerCase()}`;

    return [
        {id: 'id', displayName: '', type: 'hidden', defaultValue: id},
        {id: 'dateString', displayName: t.date, type: 'text', defaultValue: dateString},
        {id: 'totalCost', displayName: t.cost, type: 'text-number', defaultValue: totalCost},
        {id: 'energy', displayName: t.energy, type: 'text-number', defaultValue: energy},
        {id: 'hotWater', displayName: t.hotWater, type: 'text-number', defaultValue: hotWater},
        {id: 'coldWater', displayName: t.coldWater, type: 'text-number', defaultValue: coldWater},
        {
            id: 'energyCost',
            displayName: `${t.energy} ${cost}`,
            type: 'text-number',
            defaultValue: energyCost
        },
        {
            id: 'coldWaterCost',
            displayName: `${t.coldWater} ${cost}`,
            type: 'text-number',
            defaultValue: coldWaterCost
        },
        {id: 'heat', displayName: `${t.heat} ${cost}`, type: 'text-number', defaultValue: heat}
    ];
}
