import {useWasInViewport} from '@mp/common/utils/wasInViewPort';
import React, {useRef, useState} from 'react';

interface ImageProps {
    src: string;
    loadingSrc?: string;
    errorSrc?: string;
    alt?: string;
}
export function Image(props: ImageProps): JSX.Element {
    const {src, alt, loadingSrc, errorSrc} = props;
    const [isError, setIsError] = useState(false);
    const ref = useRef(null);
    const isInViewport = useWasInViewport(ref);

    if (isError && errorSrc) {
        return <img src={errorSrc} alt="error-src" />;
    }

    return <img ref={ref} src={isInViewport ? src : loadingSrc} alt={alt ?? src} onError={() => setIsError(true)} />;
}
