import {service} from '@mp/common/api';
import {toDate} from '@mp/common/utils/converters';
import {CostEstimateListItem} from '../types';

interface CostEstimateListItemDto {
    id: number;
    name: string;
    start: string;
    end: string;
}

export async function loadCostsEstimateListService(): Promise<Array<CostEstimateListItem>> {
    return service.get<Array<CostEstimateListItemDto>>('costsEstimateList').then(({data}) => {
        return data.map((item: CostEstimateListItemDto): CostEstimateListItem => {
            return {
                id: item.id,
                name: item.name,
                start: toDate(item.start),
                end: toDate(item.end)
            };
        });
    });
}
