import {service} from '@mp/common/api';
import {isEmpty} from '@mp/common/utils/array';
import {AxiosResponse} from 'axios';
import {ToWatchMovie} from '../types';

interface ToWatchMovieDTO {
    id: string;
    fid: string;
    title_pl: string;
    title_en: string;
    year: string;
    poster: string;
}

export function loadToWatchMoviesService(): Promise<Array<ToWatchMovie>> {
    return service
        .get<Array<ToWatchMovieDTO>>('movies.loadToWatch')
        .then((result: AxiosResponse<Array<ToWatchMovieDTO>>) => resolveData(result.data));
}

function resolveData(rawData: Array<ToWatchMovieDTO>): Array<ToWatchMovie> {
    if (isEmpty(rawData)) {
        return [];
    }
    return rawData.map(({id, fid, title_en, title_pl, year, poster}) => ({
        id: Number(id),
        fid: Number(fid),
        titleEn: title_en,
        titlePl: title_pl,
        year: Number(year),
        poster
    }));
}
