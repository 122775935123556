import './_checklistPage.scss';
import {Color} from '@mp/common/colors';
import {SimpleToolBar} from '@mp/common/components/tool-bar';
import {useNumberQueryParams} from '@mp/common/hooks/useQueryParams';
import {ContextMenuModal} from '@mp/common/modals/contex-menu/ui/ContextMenuModal';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {Icon} from '@mp/common/svg';
import {isEmpty} from '@mp/common/utils/array';
import {Router} from '@mp/route';
import React from 'react';
import {NavigateFunction} from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import {Loading} from '../../../components/loading';
import {ToolbarCustomButton, ToolsBar} from '../../../components/tools-bar';
import {
    clearCheckListService,
    deleteChecklistItemService,
    insertChecklistItemService,
    insertChecklistCategoryService,
    loadChecklistsService,
    updateChecklistItemCheckedService,
    updateChecklistItemService
} from '../services';
import {Checklist, ChecklistItem} from '../types';
import {getFormsModalData} from '../utils/getFormsModalData';
import {CheckRow} from './CheckRow';

const MP_CHECKLIST: string = 'mp-checklist';

export interface ChecklistPageProps {
    selectedListId: number;
    selectedItemId: number;
    navigate: NavigateFunction;
}

export interface ChecklistPageState {
    checklists: Array<Checklist>;
    addModalVisible: boolean;
    listDisabled: boolean;
    contextMenuItem: ChecklistItem;
}

export function ChecklistPage(): JSX.Element {
    const navigate: NavigateFunction = useNavigate();

    return (
        <ChecklistPageCC selectedListId={useNumberQueryParams('list')} selectedItemId={useNumberQueryParams('item')} navigate={navigate} />
    );
}

class ChecklistPageCC extends React.Component<ChecklistPageProps, ChecklistPageState> {
    constructor(props: ChecklistPageProps) {
        super(props);

        this.state = {
            checklists: [],
            addModalVisible: false,
            listDisabled: false,
            contextMenuItem: null
        };
    }

    componentDidMount(): void {
        this.loadData();
    }

    private loadData(): void {
        loadChecklistsService().then((result) => this.setState({checklists: result.categories, addModalVisible: false}));
    }

    public render() {
        const {selectedListId} = this.props;
        const {checklists: checklist, addModalVisible} = this.state;

        if (!isEmpty(checklist)) {
            return (
                <div className={MP_CHECKLIST}>
                    {this.renderToolbar()}
                    {(addModalVisible || this.props.selectedItemId) && this.renderModal()}
                    {!!selectedListId ? this.renderListItems() : this.renderListNames()}
                </div>
            );
        }
        return <Loading />;
    }

    private renderToolbar(): JSX.Element {
        const {selectedListId} = this.props;
        const {checklists} = this.state;
        if (selectedListId) {
            const currentChecklist: Checklist = checklists.find(({id}) => id === selectedListId);
            if (!currentChecklist) {
                return null;
            }
            const isAnyItemChecked: boolean = !!currentChecklist.items.find((item) => item.checked);

            const clearListButton: ToolbarCustomButton = isAnyItemChecked
                ? {icon: Icon.Circle, onCustomClick: () => clearCheckListService(selectedListId).then(() => window.location.reload())}
                : null;

            return (
                <ToolsBar
                    name={currentChecklist.name}
                    onAddClick={() => this.setState({addModalVisible: true})}
                    onBackClicked={() => null}
                    customButton={clearListButton}
                />
            );
        }
        return (
            <SimpleToolBar name="Checklisty" backgroundColor={Color.BlueLight} onAddClick={() => this.setState({addModalVisible: true})} />
        );
    }

    private renderModal(): JSX.Element {
        const {selectedListId} = this.props;
        if (selectedListId) {
            const selectedListItem: ChecklistItem = this.getSelectedItem();
            if (selectedListItem) {
                return (
                    <FormsModal<ChecklistItem>
                        title={`Edytuj ${selectedListItem.name}`}
                        mode="update"
                        inputs={getFormsModalData(selectedListId, selectedListItem)}
                        handleClose={() => this.props.navigate(Router.getUrlToChecklistPage({list: selectedListId}))}
                        onSendForm={(data) => updateChecklistItemService(data).then((res) => res.success && this.loadData())}
                    />
                );
            }

            if (this.state.addModalVisible) {
                return (
                    <FormsModal<ChecklistItem>
                        title="Dodaj do listy"
                        mode="add"
                        inputs={getFormsModalData(selectedListId)}
                        handleClose={() => this.setState({addModalVisible: false})}
                        onSendForm={(data) => insertChecklistItemService(data).then((res) => res.success && this.loadData())}
                    />
                );
            }
            return null;
        }
        return (
            <FormsModal<Checklist>
                title="Dodaj listę"
                mode="add"
                inputs={[{id: 'name', type: 'text', defaultValue: '', displayName: 'Nazwa', validation: {minLength: 3}}]}
                handleClose={() => this.setState({addModalVisible: false})}
                onSendForm={(data) => insertChecklistCategoryService(data).then((res) => res.success && this.loadData())}
            />
        );
    }

    private renderListNames(): JSX.Element {
        return <div className={`${MP_CHECKLIST}-names`}>{this.state.checklists.map((checklist) => this.renderListName(checklist))}</div>;
    }

    private renderListName(checklist: Checklist): JSX.Element {
        return (
            <div key={checklist.id}>
                <Link to={Router.getUrlToChecklistPage({list: checklist.id})}>{checklist.name}</Link>
            </div>
        );
    }

    private renderListItems(): JSX.Element {
        const {selectedListId} = this.props;
        const {checklists, contextMenuItem} = this.state;
        const checklist: Checklist = checklists.find((f) => f.id === selectedListId);
        if (checklists && checklist) {
            return (
                <div>
                    <div className={`${MP_CHECKLIST}-items`}>{checklist.items.map((item) => this.renderListItemRow(item))}</div>
                    {contextMenuItem != null && (
                        <ContextMenuModal
                            title={contextMenuItem.name}
                            onEdit={() =>
                                this.props.navigate(Router.getUrlToChecklistPage({list: selectedListId, item: contextMenuItem.id}))
                            }
                            onDelete={() => deleteChecklistItemService(contextMenuItem.id).then(({success}) => success && this.loadData())}
                            onClose={() => this.setState({contextMenuItem: null})}
                        />
                    )}
                </div>
            );
        }
    }

    private renderListItemRow(item: ChecklistItem): JSX.Element {
        const {selectedListId} = this.props;
        return (
            <CheckRow
                key={item.id}
                item={item}
                onContextMenu={() => {
                    this.setState({contextMenuItem: item});
                }}
                onClick={() => {
                    if (!this.state.listDisabled) {
                        const newCheckedState: boolean = !item.checked;
                        updateChecklistItemCheckedService(item.id, newCheckedState).then((response) => {
                            if (response.success) {
                                const newChecklistState: Array<Checklist> = this.state.checklists;
                                newChecklistState.find((f) => f.id === selectedListId).items.find((f) => f.id === item.id).checked =
                                    newCheckedState;
                                this.setState({
                                    listDisabled: false,
                                    checklists: newChecklistState
                                });
                            }
                        });
                    }
                    this.setState({listDisabled: true});
                }}
            />
        );
    }

    private getSelectedItem(): ChecklistItem {
        const {selectedListId, selectedItemId} = this.props;

        if (selectedListId && selectedItemId) {
            const currentList: Checklist = this.state.checklists.find((checklist) => checklist.id === this.props.selectedListId);
            return currentList?.items?.find((item) => item.id === selectedItemId);
        }
        return null;
    }
}
