import {Button} from '@mp/common/components/button/ui/Button';
import {Icon, SvgButton, SvgIcon} from '@mp/common/svg';
import {Router} from '@mp/route';
import React from 'react';
import {Link} from 'react-router-dom';
import {Category, Item, View} from '../types';
import {getDarkColor, getLightColor} from '../utils/colors';

interface ItemListViewProps {
    category: Category;
    items: Array<Item>;
    isCompleted: boolean;
    detailsVisible: boolean;
}

export function ItemListView(props: ItemListViewProps): JSX.Element {
    const {category, items, isCompleted, detailsVisible} = props;
    const {id, name} = category;
    const lightColor = getLightColor(id);
    const darkColor = getDarkColor(id);

    return (
        <div className="items-list">
            <div id="bucketlist-items-list-view-color" style={{backgroundColor: lightColor}}>
                <div className={`category-title`}>
                    <Link to={Router.getUrlToBucketListPage({categoryId: null, view: null, editItemId: null})}>
                        <SvgButton icon={Icon.ArrowLeft} color="black" />
                    </Link>
                    <span>{name}</span>
                    <Link to={Router.getUrlToBucketListPage({categoryId: category.id, view: View.ADD_ITEM, editItemId: null})}>
                        <SvgButton icon={Icon.Plus} color="black" />
                    </Link>
                </div>
                <div className="bucket-list-tab">
                    <Link
                        to={Router.getUrlToBucketListPage({categoryId: category.id, view: View.NOT_COMPLETED_ITEMS_LIST, editItemId: null})}
                    >
                        <Button title="Do zrobienia" style={{backgroundColor: darkColor}} disabled={!isCompleted} />
                    </Link>
                    <Link to={Router.getUrlToBucketListPage({categoryId: category.id, view: View.COMPLETED_ITEMS_LIST, editItemId: null})}>
                        <Button title="Zakończone" style={{backgroundColor: darkColor}} disabled={isCompleted} />
                    </Link>
                </div>
                {items.map((item: Item) => {
                    return (
                        <div key={item.id} className="bucket-list-item" style={{backgroundColor: darkColor}}>
                            <div className="bucket-list-item-label">
                                <span className="title">
                                    {item.title} {isCompleted ? `(${item.completionDate})` : null}
                                </span>
                                <div>
                                    <span className="details-button">
                                        {item.description && item.description.trim() && (
                                            <SvgIcon
                                                icon={Icon.CircleInfo}
                                                color="black"
                                                style={{width: '16px'}}
                                                onClick={(event) => toggleItemDetails(event)}
                                            />
                                        )}
                                    </span>
                                    {!isCompleted && (
                                        <Link
                                            to={Router.getUrlToBucketListPage({
                                                categoryId: category.id,
                                                view: View.EDIT_ITEM,
                                                editItemId: item.id
                                            })}
                                        >
                                            <span className="edit-button">
                                                <SvgIcon icon={Icon.PenToSquare} color="black" style={{width: '16px'}} />
                                            </span>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div
                                className={`details-container ${
                                    detailsVisible && item.description && item.description.trim() ? 'visible' : null
                                }`}
                            >
                                {item.description}
                            </div>
                        </div>
                    );
                })}
                <div className="footer" style={{backgroundColor: lightColor}} />
            </div>
        </div>
    );
}

function toggleItemDetails(event: React.MouseEvent) {
    const divElement: HTMLElement = event.currentTarget.parentElement.parentElement.parentElement.parentElement;
    divElement.querySelector('.details-container').classList.toggle('visible');
}
