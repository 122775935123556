import {service, ServiceResponseWithAffectedRow, ServiceResponseWithId} from '@mp/common/api';
import {CostEstimateSection} from '../types';

const SERVICE_NAME = 'costsEstimateSections';

export function addCostsEstimateSectionService(data: Omit<CostEstimateSection, 'items'>): Promise<ServiceResponseWithId> {
    return service.post(SERVICE_NAME, {data});
}

export function editCostsEstimateSectionService(data: Omit<CostEstimateSection, 'items'>): Promise<ServiceResponseWithAffectedRow> {
    return service.put(SERVICE_NAME, {data});
}
