import {Icon, SvgButton} from '@mp/common/svg';
import {searchMoviesService} from '@mp/filmweb/services/searchMovies.service';
import {Film, SearchMovieResult} from '@mp/filmweb/types';
import {Router} from '@mp/route';
import {SpeechRecognition} from '@mp/speech-recognition/ui/SpeechRecognition';
import React from 'react';
import {NavigateFunction} from 'react-router/dist/lib/hooks';
import {Link, useNavigate} from 'react-router-dom';
import {Loading} from '../../../../components/loading';
import {FilmResultItem} from './FilmResultItem';
import {MovieDetailsWindow} from './MovieDetailsWindow';

const SEARCH_LINK_ID: string = 'search-link-id';

interface SearchMovieProps {
    query: string;
    navigate: NavigateFunction;
}

interface SearchMoviesState {
    searchQuery: string;
    movies: Array<SearchMovieResult>;
    searchDisabled: boolean;
    isLoaded: boolean;
    currentMovie: SearchMovieResult;
    movieDetailsWindowData: SearchMovieResult;
}

export function SearchMovies(props: Pick<SearchMovieProps, 'query'>): JSX.Element {
    const navigate = useNavigate();

    return <SearchMoviesCC query={props.query} navigate={navigate} />;
}

class SearchMoviesCC extends React.Component<SearchMovieProps, SearchMoviesState> {
    private searchResultsMap: Map<string, Array<SearchMovieResult>>;
    private searchInputRef: HTMLInputElement;

    constructor(props: SearchMovieProps) {
        super(props);
        this.searchResultsMap = new Map<string, Array<SearchMovieResult>>();

        this.handleChange = this.handleChange.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.onAddMovieClick = this.onAddMovieClick.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);

        this.state = {
            searchQuery: props.query ?? '',
            movies: [],
            searchDisabled: false,
            isLoaded: true,
            currentMovie: null,
            movieDetailsWindowData: null
        };
    }

    public render() {
        return (
            <search-movies>
                {this.renderDetailsWindow()}
                {this.renderSearchComponent()}
                {this.state.isLoaded ? this.renderFilmResultsList() : <Loading />}
            </search-movies>
        );
    }

    public componentDidMount(): void {
        if (this.state.searchQuery) {
            this.executeSearch();
        }
    }

    public componentDidUpdate(prevProps: SearchMovieProps) {
        if (prevProps.query?.trim() !== this.props.query?.trim()) {
            this.executeSearch(this.props.query?.trim() ?? '');
        }
    }

    private renderDetailsWindow(): JSX.Element {
        if (this.state.movieDetailsWindowData) {
            return (
                <MovieDetailsWindow
                    movieDetailsWindowData={this.state.movieDetailsWindowData}
                    onMovieUpdateSuccess={(id, watched) => {
                        const updatedMovie: SearchMovieResult = this.state.movies.find((movie) => movie.fid === id);
                        updatedMovie.info.exist = true;
                        updatedMovie.info.watched = watched;
                        updatedMovie.info.toWatch = !watched;
                        this.closeDetailsWindow();
                    }}
                    onExitCallback={() => this.closeDetailsWindow()}
                />
            );
        }
    }

    private closeDetailsWindow(): void {
        this.setState({movieDetailsWindowData: null});
    }

    private renderSearchComponent() {
        const {searchQuery} = this.state;
        return (
            <div className="search-component">
                <input
                    ref={(el) => (this.searchInputRef = el)}
                    type="text"
                    autoCapitalize="none"
                    defaultValue={searchQuery}
                    onChange={(e) => this.handleChange(e)}
                    onKeyUp={(e) => this.handleKeyUp(e)}
                />
                <SpeechRecognition
                    onSpeechEnd={(text) => {
                        this.searchInputRef.value = text;
                        this.props.navigate(Router.getUrlToMoviePage('search', text));
                    }}
                />
                <Link id={SEARCH_LINK_ID} to={Router.getUrlToMoviePage('search', searchQuery)}>
                    <SvgButton icon={Icon.MagnifyingGlass} />
                </Link>
            </div>
        );
    }

    private handleKeyUp(event: React.KeyboardEvent) {
        event.preventDefault();
        if (['Enter', 'NumpadEnter'].includes(event.key) && !this.state.searchDisabled) {
            document.getElementById(SEARCH_LINK_ID).click();
        }
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        this.setState({searchQuery: value.trim()});
    }

    private executeSearch(query?: string): void {
        const searchQuery: string = query ?? this.state.searchQuery;
        if (!searchQuery) {
            this.setState({movies: []});
            return;
        }
        const cachedResult: Array<SearchMovieResult> = this.searchResultsMap.get(searchQuery);
        if (cachedResult) {
            this.setState({movies: cachedResult});
            return;
        }

        this.setState({isLoaded: false, searchDisabled: true});

        searchMoviesService(searchQuery, 0, true)
            .then((res) => {
                this.searchResultsMap.set(searchQuery, res);
                this.setState({movies: res});
            })
            .then(() => this.setState({isLoaded: true, searchDisabled: false}));
    }

    private renderFilmResultsList(): JSX.Element {
        const {movies} = this.state;
        if (movies == null) {
            return <div style={{textAlign: 'center'}}>Brak wyników</div>;
        }
        return (
            <>
                {movies.map((m: Film) => (
                    <FilmResultItem
                        key={m.fid}
                        film={m}
                        onDownloadFilmDetails={(updatedFilm) => this.onDownloadFilmDetails(updatedFilm)}
                        onAddMovieClick={(film: Film) => this.onAddMovieClick(film)}
                    />
                ))}
            </>
        );
    }

    private onDownloadFilmDetails(updatedFilm: Film): void {
        const moviesState: Array<Film> = this.state.movies;
        const filmToUpdate: Film = moviesState.find((f) => f.fid === updatedFilm.fid);
        filmToUpdate.info = updatedFilm.info;
        filmToUpdate.rating = updatedFilm.rating;
        filmToUpdate.criticsRating = updatedFilm.criticsRating;

        this.setState({movies: moviesState});
    }

    private onAddMovieClick(movie: SearchMovieResult): void {
        this.setState({movieDetailsWindowData: movie});
    }
}
