import React, {useEffect, useRef, useState} from 'react';
import {Button} from '../components/button/ui/Button';
import {FormInput, FormInputRef} from '../components/form-input/FormInput';
import {InputData} from '../components/form-input/types';
import {toBoolean} from '../utils/converters';
import {switchGuard} from '../utils/switchGuard';
import {Modal} from './Modal';

const DELETE_CLICKS: number = 3;

interface FormsModalProps<T> {
    title: string;
    mode: 'add' | 'update' | 'delete';
    inputs: Array<InputData<T>>;
    handleClose: () => void;
    onSendForm: (data: T) => Promise<void>;
    onDeleteClick?: (id: number) => Promise<void>;
}

const useRefs = () => {
    const refsByKey = useRef<Record<string, FormInputRef | null>>({});

    const setRef = (element: FormInputRef, key: string): void => {
        refsByKey.current[key] = element;
    };

    return {refs: refsByKey.current, setRef};
};

export function FormsModal<T>(params: FormsModalProps<T>): JSX.Element {
    const {title, mode, inputs, handleClose, onSendForm, onDeleteClick} = params;
    const [formInputs] = useState<Array<InputData<T>>>(inputs.filter(Boolean));
    const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
    const [deleteClickedTimes, setDeleteClickedTimes] = useState<number>(0);
    const {refs, setRef} = useRefs();
    const initialFormStringData = useRef<string>();

    useEffect(() => {
        initialFormStringData.current = JSON.stringify(getInputsDataParams());
    }, []);

    return (
        <Modal
            title={title}
            handleClose={handleClose}
            footer={
                <div>
                    {onDeleteClick && (
                        <Button
                            title={`Usuń (${DELETE_CLICKS - deleteClickedTimes})`}
                            disabled={isFormDisabled}
                            onClick={handleOnDeleteClick}
                        />
                    )}
                    <Button
                        title={resolveActionButtonName()}
                        disabled={isFormDisabled}
                        onClick={() => {
                            if (onSendForm && isFormValid()) {
                                const formDataObject: T = getInputsDataParams() as T;

                                if (initialFormStringData.current !== JSON.stringify(formDataObject)) {
                                    setIsFormDisabled(true);
                                    onSendForm(formDataObject).then(() => {
                                        setIsFormDisabled(false);
                                    });
                                } else {
                                    handleClose();
                                }
                            }
                        }}
                    />
                </div>
            }
        >
            <div style={{margin: '0 10px'}}>
                {formInputs.map((input: InputData<T>) => (
                    <FormInput
                        key={String(input.id)}
                        id={String(input.id)}
                        selectOptions={input.selectOptions}
                        ref={(current) => setRef(current as FormInputRef, String(input.id))}
                        description={input.displayName}
                        inputType={input.type}
                        validate={input.validation}
                        defaultValue={input.defaultValue}
                        disabled={input.disabled}
                    />
                ))}
            </div>
        </Modal>
    );

    function handleOnDeleteClick(): void {
        const id: number = getObjectId();
        if (id) {
            setDeleteClickedTimes((prev) => prev + 1);
        }
        if (deleteClickedTimes === DELETE_CLICKS - 1) {
            setIsFormDisabled(true);
            onDeleteClick(id).then(() => {
                handleClose();
            });
        }
    }

    function getObjectId(): number {
        let id: number;
        for (const formInput of getInputs()) {
            if (formInput.getId() === 'id') {
                id = Number(formInput.getValue());
            }
        }
        return id;
    }

    function getInputsDataParams(): T {
        const obj: T = {} as T;
        for (const formInput of getInputs()) {
            const returnType = formInput.getInputReturnType();
            const rawValue: string = formInput.getValue();

            const objId: string = formInput.getId();
            if (returnType === 'number') {
                obj[objId] = rawValue == null ? null : Number(rawValue);
            } else if (returnType === 'boolean') {
                obj[objId] = toBoolean(rawValue);
            } else if (returnType === 'date') {
                obj[objId] = rawValue || null;
            } else {
                obj[objId] = rawValue;
            }
        }
        return obj;
    }

    function isFormValid(): boolean {
        let isValid: boolean = true;
        for (const input of getInputs()) {
            if (!input.isValid()) {
                isValid = false;
            }
        }
        return isValid;
    }

    function getInputs(): Array<FormInputRef> {
        return Array.from(Object.values(refs));
    }

    function resolveActionButtonName(): string {
        switch (mode) {
            case 'add':
                return 'Dodaj';
            case 'update':
                return 'Aktualizuj';
            case 'delete':
                return 'Usuń';
            default:
                switchGuard(mode);
        }
    }
}
