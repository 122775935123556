import './_cashEnvelopePage.scss';
import {Color} from '@mp/common/colors';
import {Button} from '@mp/common/components/button/ui/Button';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {Icon, SvgButton} from '@mp/common/svg';
import {toCurrency} from '@mp/common/utils/number';
import {Router} from '@mp/route';
import {QRCodeCanvas} from 'qrcode.react';
import React, {MutableRefObject, useRef, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {ORIGIN} from '../../../global';
import {deleteCashEnvelopeService, updateCashEnvelopeService} from '../services/cashEnvelopes.service';
import {CashEnvelope} from '../types';
import {Envelope} from './Envelope';

const VALUES: Array<number> = [10, 20, 50, 100, 200, 500];
const INITIAL_VALUE: number = 0;

export function CashEnvelopePage({cashEnvelope, onUpdate}: {cashEnvelope: CashEnvelope; onUpdate: () => void}): JSX.Element {
    const [shouldAdd, setShouldAdd] = useState<boolean>(null);
    const [value, setValue] = useState<number>(INITIAL_VALUE);
    const [isNameChanged, setIsNameChanged] = useState<boolean>(false);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState<boolean>(false);
    const nameInputRef: MutableRefObject<HTMLInputElement> = useRef(null);

    return (
        <div className="cash-envelope-page">
            <Envelope cashEnvelope={cashEnvelope} />
            <div className="name-container">
                <TextInput onChange={onNameChanged} title="Nazwa" reference={nameInputRef} defaultValue={cashEnvelope.name} />
            </div>
            <div className="select-subtract-or-add">
                <SvgButton
                    icon={Icon.Minus}
                    style={{background: shouldAdd === false ? Color.Green : Color.GrayDark}}
                    onClick={() => onSubtractOrAddIconClick(false)}
                />
                <SvgButton
                    icon={Icon.Plus}
                    style={{background: shouldAdd === true ? Color.Green : Color.GrayDark}}
                    onClick={() => onSubtractOrAddIconClick(true)}
                />
            </div>
            {renderValueToSubtractOrAdd()}
            {renderNominalValues()}
            <Button title="QR" onClick={() => handleNewWindowQR(cashEnvelope.id)} />
            <Button title="Usuń" onClick={() => setDeleteConfirmationVisible(true)} />
            <Button title="Aktualizuj" disabled={value === INITIAL_VALUE && !isNameChanged} onClick={handleUpdateClick} />
            {deleteConfirmationVisible && (
                <ConfirmModal
                    title="Usuwannie koperty"
                    question="Czy na pewno usunąć kopertę?"
                    handleClose={() => setDeleteConfirmationVisible(false)}
                    handleConfirm={() => deleteCashEnvelopeService(Number(cashEnvelope.id)).then(({success}) => success && onUpdate())}
                />
            )}
        </div>
    );

    function onNameChanged(): void {
        setIsNameChanged((cashEnvelope.name ?? '') !== nameInputRef.current.value);
    }

    function handleUpdateClick(): void {
        updateCashEnvelopeService({
            ...cashEnvelope,
            value: cashEnvelope.value + value,
            name: nameInputRef.current.value || null
        }).then(({success}) => success && onUpdate());
    }

    function onSubtractOrAddIconClick(_shouldAdd: boolean): void {
        setShouldAdd(_shouldAdd);
        setValue(INITIAL_VALUE);
    }

    function renderNominalValues(): JSX.Element {
        return (
            <div className="nominal-value">
                {VALUES.map((v) => (
                    <Button
                        title={v}
                        disabled={shouldAdd === null}
                        key={v}
                        onClick={() => (shouldAdd ? setValue(value + v) : setValue(value - v))}
                    />
                ))}
            </div>
        );
    }

    function renderValueToSubtractOrAdd(): JSX.Element {
        return <div className="value-to-subtract-or-add">{toCurrency(value)}</div>;
    }
}

function handleNewWindowQR(cashEnvelopeId: string): void {
    const newWindow: Window = window.open('', '_blank', '');
    newWindow.document.write('<div id="root"/>');
    createRoot(newWindow.document.getElementById('root')).render(
        <div style={{display: 'flex', flexDirection: 'column', width: 'fit-content', padding: '4px', border: 'dashed 1px gray'}}>
            <div style={{margin: 'auto', fontSize: '24px', lineHeight: '28px'}}>{cashEnvelopeId}</div>
            <QRCodeCanvas value={ORIGIN + Router.getUrlToCashEnvelopesPage(cashEnvelopeId)} size={150}></QRCodeCanvas>
        </div>
    );
}
