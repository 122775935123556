import './_recipesGrid.scss';
import {Image} from '@mp/common/components/image/ui/Image';
import {useQueryParams} from '@mp/common/hooks/useQueryParams';
import {ContextMenuModal} from '@mp/common/modals/contex-menu/ui/ContextMenuModal';
import {FormsModal} from '@mp/common/modals/FormsModal';
import {toLowerCaseAndReplacePolishChars} from '@mp/common/utils/string';
import {FILTER_PARAM, Router} from '@mp/route';
import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {isAdmin} from '../../../global';
import {NO_IMAGE} from '../images';
import {addRecipeService, updateIsCompleted} from '../services';
import {Recipe, RecipeCategory} from '../types';
import {RecipesToolbar} from './RecipesToolbar';
import {getInputsData} from './utils/getInputsData';

interface RecipeListProps {
    recipes: Array<Recipe>;
    recipeCategories: Array<RecipeCategory>;
}
export function RecipesGrid(props: RecipeListProps): JSX.Element {
    const {recipes, recipeCategories} = props;

    const [searchPhrase, setSearchPhrase] = useState<string>('');
    const [filteredRecipes, setFilteredRecipes] = useState<Array<Recipe>>(recipes);
    const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
    const [selectedRecipe, setSelectedRecipe] = useState<Recipe>(null);
    const categoryFilterName: string = useQueryParams(FILTER_PARAM);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        let _recipes: Array<Recipe> = Array.from(recipes);
        if (categoryFilterName != null) {
            const categoryFilterId: string = getSelectedCategoryFilter().id;
            _recipes = _recipes.filter(({categoryIds}) => categoryIds.includes(categoryFilterId));
        }
        if (searchPhrase != null) {
            _recipes = _recipes.filter(({name}) =>
                toLowerCaseAndReplacePolishChars(name).includes(toLowerCaseAndReplacePolishChars(searchPhrase))
            );
        }
        if (categoryFilterName != null || searchPhrase != null) {
            setFilteredRecipes(_recipes);
        }
    }, [searchPhrase, categoryFilterName]);

    return (
        <>
            <RecipesToolbar
                allFilters={recipeCategories}
                selectedFilter={getSelectedCategoryFilter()}
                searchPhrase={searchPhrase}
                onAddButtonClick={() => setIsAddModalVisible(true)}
                onSearchTextChanged={(phrase: string) => setSearchPhrase(phrase)}
            />
            <div className="recipes-grid">
                {filteredRecipes.map((recipe) => {
                    return (
                        <div
                            key={recipe.id}
                            className="recipes-grid-item"
                            onClick={() => navigate(Router.getUrlToRecipesPage({id: recipe.id}))}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                setSelectedRecipe(recipe);
                            }}
                        >
                            <div className={resolveMissingDataClass(recipe)}>{recipe.name}</div>
                            <Image src={recipe.image} loadingSrc={NO_IMAGE} alt={'img-' + recipe.name.toLowerCase()} errorSrc={NO_IMAGE} />
                        </div>
                    );
                })}
            </div>
            {isAddModalVisible && <AddRecipeModal />}
            {selectedRecipe && (
                <ContextMenuModal title={selectedRecipe.name} onClose={() => setSelectedRecipe(null)}>
                    <ContextMenuModal.Option
                        label="Edytuj przepis"
                        onClick={() => navigate(Router.getUrlToRecipesPage({id: selectedRecipe.id, isEdit: true}))}
                    />
                    <ContextMenuModal.Option
                        label="Edytuj zdjecie"
                        onClick={() => navigate(Router.getUrlToRecipesPage({id: selectedRecipe.id, isEditImage: true}))}
                    />
                    {isAdmin() && (
                        <ContextMenuModal.Option
                            label={selectedRecipe.isCompleted ? `Ustaw "niekompletny"` : `Ustaw "kompletny"`}
                            onClick={() =>
                                updateIsCompleted({id: selectedRecipe.id, isCompleted: !selectedRecipe.isCompleted}).then(
                                    ({success}) => success && window.location.reload()
                                )
                            }
                        />
                    )}
                </ContextMenuModal>
            )}
        </>
    );

    function getSelectedCategoryFilter(): RecipeCategory {
        return recipeCategories.find(({name}) => name === categoryFilterName);
    }

    function AddRecipeModal(): JSX.Element {
        return (
            <FormsModal
                mode="add"
                title="Dodaj przepis"
                inputs={getInputsData(recipeCategories)}
                onSendForm={(data: object) => addRecipeService(data as Recipe).then(({success}) => success && setIsAddModalVisible(false))}
                handleClose={() => setIsAddModalVisible(false)}
            />
        );
    }
}

function resolveMissingDataClass(recipe: Recipe): string {
    return recipe.isCompleted ? '' : 'missing-data';
}
