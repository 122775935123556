import './_expensesSummary.scss';
import {lastElement, secondToLastElement} from '@mp/common/utils/array';
import {getMonthName} from '@mp/common/utils/date';
import {numberWithUnit} from '@mp/common/utils/unit';
import React from 'react';
import {t} from '../i18n';
import {PaymentRow} from '../types';
import {calculateExpenses} from '../utils/calculateExpenses';

export function ExpensesSummary(props: {expenses: Array<PaymentRow>; previousYearExpense: PaymentRow; showTitle?: boolean}): JSX.Element {
    const {expenses, showTitle = true} = props;
    const lastRow = lastElement(expenses);
    const secondToLastRow = secondToLastElement(expenses) ?? props.previousYearExpense;
    if (secondToLastRow == null) {
        return <div />;
    }

    const {usedEnergy, usedColdWater, heatCost, energyCost, coldWaterCost, totalCost} = calculateExpenses(lastRow, secondToLastRow);

    if (totalCost == null) {
        return <div>Uzupełnij wszystkie dane za ostatni miesiąc</div>;
    }

    const date: Date = new Date(lastRow.dateString);

    if (expenses.length >= 2) {
        return (
            <div className="expenses-summary">
                {showTitle && <div className="expenses-summary-title">{`${getMonthName(date)} ${date.getFullYear()}`}</div>}
                <table className="summary">
                    <tbody>
                        <tr>
                            <td>{t.energy.toLowerCase()}</td>
                            <td>{numberWithUnit(lastRow.energyCost, 'zł/kWh', 2)}</td>
                            <td>{numberWithUnit(usedEnergy, 'kWh', 2)}</td>
                            <td>{numberWithUnit(energyCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>{t.coldWater.toLowerCase()}</td>
                            <td>{numberWithUnit(lastRow.coldWaterCost, 'zł/m3', 2)}</td>
                            <td>{numberWithUnit(usedColdWater, 'm3', 3)}</td>
                            <td>{numberWithUnit(coldWaterCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>{t.heat.toLowerCase()}</td>
                            <td></td>
                            <td></td>
                            <td>{numberWithUnit(heatCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>fundusz remontowy</td>
                            <td></td>
                            <td></td>
                            <td>{numberWithUnit(lastRow.renovationFundCost, 'zł', 2)}</td>
                        </tr>
                        <tr>
                            <td>śmieci</td>
                            <td></td>
                            <td></td>
                            <td>{numberWithUnit(lastRow.trashCost, 'zł', 2)}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="summary-sum">
                    <tbody>
                        <tr>
                            <td>do zapłaty</td>
                            <td></td>
                            <td></td>
                            <td>{numberWithUnit(totalCost, 'zł', 2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
