import './_dateTImePicker.scss';
import {Icon, SvgIcon} from '@mp/common/svg';
import {FormInputRequiredParams} from '@mp/common/types';
import {addDay} from '@mp/common/utils/date';
import {zeroPrefix} from '@mp/common/utils/string';
import React from 'react';
import {addMinutes, subMinutes} from './utils';

const MP_DATE_TIME_PICKER = 'mp-date-time-picker';
const MP_DATE_TIME_PICKER_RELATIVE = `${MP_DATE_TIME_PICKER}-relative`;
const MP_DATE_TIME_PICKER_ADD = `${MP_DATE_TIME_PICKER}-add`;
const MP_DATE_TIME_PICKER_VIEW = `${MP_DATE_TIME_PICKER}-view`;
const MP_DATE_TIME_PICKER_SUB = `${MP_DATE_TIME_PICKER}-sub`;

interface DateTimePickerProps extends FormInputRequiredParams {
    date: Date;
}

interface DateTimePickerState {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
}

export class DateTimePicker extends React.Component<DateTimePickerProps, DateTimePickerState> {
    constructor(props: DateTimePickerProps) {
        super(props);

        if (props.disabled != null) {
            throw new Error('not implemented');
        }

        this.onHourAddClick = this.onHourAddClick.bind(this);
        this.onHourSubClick = this.onHourSubClick.bind(this);
        this.onMinuteAddClick = this.onMinuteAddClick.bind(this);
        this.onMinuteSubClick = this.onMinuteSubClick.bind(this);

        const date: Date = this.props.date;
        this.state = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
            hour: date.getHours(),
            minute: date.getMinutes()
        };
    }

    public getDate(): Date {
        const state: DateTimePickerState = this.state;
        return new Date(state.year, state.month, state.day, state.hour, state.minute);
    }

    public getDateString(): string {
        const state: DateTimePickerState = this.state;
        return `${state.year}-${zeroPrefix(state.month)}-${zeroPrefix(state.day)} ${zeroPrefix(state.hour)}:${zeroPrefix(state.minute)}:00`;
    }

    public render() {
        const date: string = `${this.state.year}-${zeroPrefix(this.state.month)}-${zeroPrefix(this.state.day)}`;

        return (
            <mp-date-time-picker>
                <div className={MP_DATE_TIME_PICKER}>
                    <div className={MP_DATE_TIME_PICKER_RELATIVE}>
                        <SvgIcon icon={Icon.ChevronUp} className={MP_DATE_TIME_PICKER_ADD} onClick={() => this.onDayAddClick()} />
                        <span className={MP_DATE_TIME_PICKER_VIEW}>{date}</span>
                        <SvgIcon icon={Icon.ChevronDown} className={MP_DATE_TIME_PICKER_SUB} onClick={() => this.onDaySubClick()} />
                    </div>
                    <div className={MP_DATE_TIME_PICKER_RELATIVE}>
                        <SvgIcon icon={Icon.ChevronUp} className={MP_DATE_TIME_PICKER_ADD} onClick={() => this.onHourAddClick()} />
                        <span className={MP_DATE_TIME_PICKER_VIEW}>{zeroPrefix(this.state.hour)}</span>
                        <SvgIcon icon={Icon.ChevronDown} className={MP_DATE_TIME_PICKER_SUB} onClick={() => this.onHourSubClick()} />
                    </div>
                    <div>:</div>
                    <div className={MP_DATE_TIME_PICKER_RELATIVE}>
                        <SvgIcon icon={Icon.ChevronUp} className={MP_DATE_TIME_PICKER_ADD} onClick={() => this.onMinuteAddClick()} />
                        <span className={MP_DATE_TIME_PICKER_VIEW}>{zeroPrefix(this.state.minute)}</span>
                        <SvgIcon icon={Icon.ChevronDown} className={MP_DATE_TIME_PICKER_SUB} onClick={() => this.onMinuteSubClick()} />
                    </div>
                </div>
            </mp-date-time-picker>
        );
    }

    private onDayAddClick(): void {
        this.onDayChange(1);
    }

    private onDaySubClick(): void {
        this.onDayChange(-1);
    }

    private onDayChange(addDays: number): void {
        const {year, month, day} = this.state;
        const date: Date = new Date(year, month - 1, day);
        const newDate: Date = addDay(date, addDays);
        this.setState({year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: newDate.getDate()});
    }

    private onHourAddClick(): void {
        this.setState({hour: (this.state.hour + 1) % 24});
    }

    private onHourSubClick(): void {
        this.setState({hour: subHour(this.state.hour)});

        function subHour(hour: number): number {
            return hour === 0 ? 23 : hour - 1;
        }
    }

    private onMinuteSubClick(): void {
        this.setState({minute: subMinutes(this.state.minute)});
    }

    private onMinuteAddClick(): void {
        this.setState({minute: addMinutes(this.state.minute)});
    }
}
