import {service} from '@mp/common/api';
import {toDate} from '@mp/common/utils/converters';
import {copyDate, countDays, currentDate} from '@mp/common/utils/date';
import {Subscription, SubscriptionType} from '../types';

export interface LoadSubscriptionsResult {
    subscriptions: Array<Subscription>;
    totalCostPerYear: number;
}

interface RawSubscription {
    id: number;
    name: string;
    date: string;
    cost: number;
    type: SubscriptionType;
    isActive: true;
}

export function loadSubscriptionsService(): Promise<LoadSubscriptionsResult> {
    return service.get<Array<RawSubscription>>('subscriptions').then(({data}) => resolveSubscriptionsResult(data));
}

function resolveSubscriptionsResult(rawSubs: Array<RawSubscription>): LoadSubscriptionsResult {
    let totalCostPerYear: number = 0;
    const subscriptions: Array<Subscription> = rawSubs
        .map((raw): Subscription => {
            const currDate: Date = currentDate();
            const date: Date = toDate(raw.date);
            const nextPaymentDate: Date = resolveNextPaymentDate(date, raw.type);
            const daysToPayment: number = date && nextPaymentDate ? countDays(currDate, nextPaymentDate) : null;
            if (raw.type === SubscriptionType.MONTHLY) {
                totalCostPerYear += raw.cost * 12;
            } else {
                totalCostPerYear += raw.cost;
            }

            return {...raw, date, nextPaymentDate, daysToPayment, ...resolveCosts(raw.cost, raw.type)};
        })
        .sort((a, b) => a.daysToPayment - b.daysToPayment);

    return {totalCostPerYear, subscriptions};
}

function resolveCosts(cost: number, subscriptionType: SubscriptionType): Pick<Subscription, 'constMonthly' | 'constAnnual'> {
    if (subscriptionType === SubscriptionType.ANNUAL) {
        return {
            constMonthly: cost / 12,
            constAnnual: cost
        };
    }
    return {
        constMonthly: cost,
        constAnnual: cost * 12
    };
}

function resolveNextPaymentDate(date: Date, subscriptionType: SubscriptionType): Date {
    if (!date) {
        return null;
    }
    const currDate: Date = currentDate();
    if (subscriptionType === SubscriptionType.MONTHLY) {
        date = copyDate(date, {setYear: currDate.getFullYear()});
        const newDate: Date = copyDate(date, {setMonth: currDate.getMonth()});

        if (newDate.getTime() < currDate.getTime()) {
            return copyDate(date, {setMonth: currDate.getMonth() + 1});
        }
        return newDate;
    } else if (subscriptionType === SubscriptionType.ANNUAL) {
        const newDate: Date = copyDate(date, {setYear: currDate.getFullYear()});
        if (newDate.getTime() < currDate.getTime()) {
            return copyDate(date, {setYear: currDate.getFullYear() + 1});
        }
        return newDate;
    }
    return null;
}
