import './_modal.scss';
import React, {PropsWithChildren, useEffect} from 'react';
import {GrayedOutBackground} from '../components/background/ui/GrayedOutBackground';
import {Icon, SvgButton} from '../svg';

const COMMON_MODAL: string = 'mp-common-modal';

interface ModalProps extends PropsWithChildren {
    handleClose: () => void;
    title: string;
    size?: ModalSize;
    footer?: JSX.Element;
}

type ModalSize = 'small' | 'medium' | 'big';

export function Modal(props: ModalProps): JSX.Element {
    const {title, footer, handleClose, size = 'medium', children} = props;

    useEffect(() => {
        document.addEventListener('keydown', keydownEventHandler);

        return () => {
            document.removeEventListener('keydown', keydownEventHandler);
        };
    }, []);

    function keydownEventHandler(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            handleClose();
        }
    }

    return (
        <>
            <GrayedOutBackground onClick={handleClose} />
            <div className={`${COMMON_MODAL} modal-size-${size}`}>
                {title && (
                    <div className={`${COMMON_MODAL}-title`}>
                        {title}
                        <SvgButton icon={Icon.Xmark} onClick={handleClose} />
                    </div>
                )}
                <div className={`${COMMON_MODAL}-content`}>{children}</div>
                {footer && <div className={`${COMMON_MODAL}-footer`}>{footer}</div>}
            </div>
        </>
    );
}
