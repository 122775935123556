import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import {ErrorBoundary} from './components/error-boundary/ErrorBoundary';
import * as serviceWorker from './serviceWorker';

createRoot(document.getElementById('global-container')).render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>
);

/*
If you want your app to work offline and load faster, you can change
unregister() to register() below. Note this comes with some pitfalls.
Learn more about service workers: bit.ly/CRA-PWA
*/
serviceWorker.register();
