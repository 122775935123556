import './_toWatchMovies.scss';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {AxiosError} from 'axios';
import React from 'react';
import {updateToWatchedService} from '../../services';
import {ToWatchMovie} from '../../types';
import {getUpflixUrl} from '../../utils/getUpflixUrl';
import {MovieRow} from '../common/MovieRow';

export interface ToWatchMoviesProps {
    movies: Array<ToWatchMovie>;
}

interface MovieToUpdate {
    id: number;
    inCinema: boolean;
}

interface ToWatchMoviesState {
    movies: Array<ToWatchMovie>;
    movieToUpdate: MovieToUpdate;
}

export class ToWatchMoviesView extends React.Component<ToWatchMoviesProps, ToWatchMoviesState> {
    constructor(props: ToWatchMoviesProps) {
        super(props);

        this.updateMovieToUpdateState = this.updateMovieToUpdateState.bind(this);

        this.state = {
            movies: props.movies,
            movieToUpdate: null
        };
    }

    public render() {
        return (
            <div className="to-watch-movies">
                {this.renderModal()}
                {this.state.movies.map((movie: ToWatchMovie) => {
                    return (
                        <MovieRow
                            key={movie.id}
                            movie={movie}
                            onUpdate={this.updateMovieToUpdateState}
                            topElement={
                                <div style={{textAlign: 'left'}} onClick={() => window.open(getUpflixUrl(movie.titlePl))}>
                                    Gdzie obejrzeć?
                                </div>
                            }
                        />
                    );
                })}
            </div>
        );
    }

    private renderModal(): JSX.Element {
        if (this.state.movieToUpdate == null) {
            return <div />;
        }
        const {movies, movieToUpdate} = this.state;
        const {id, inCinema} = movieToUpdate;
        const title: string = movies.find((f) => f.id === id).titlePl;
        const question: string = `Czy na pewno dodać film jako:\nobejrzany w ${inCinema ? 'kinie' : 'domu'}?`;
        return (
            <ConfirmModal
                title={title}
                question={question}
                handleConfirm={() => this.updateWatchedService()}
                handleClose={() => this.setState({movieToUpdate: null})}
            />
        );
    }

    private updateWatchedService(): void {
        const {id, inCinema} = this.state.movieToUpdate;
        updateToWatchedService({id, cinema: inCinema})
            .then((response) => {
                if (response.success) {
                    this.setState({movies: this.state.movies.filter((f) => f.id !== id), movieToUpdate: null});
                } else {
                    this.setState({movieToUpdate: null});
                }
            })
            .catch((error: AxiosError) => alert(error.response.data));
    }

    private updateMovieToUpdateState(movieId: number, inCinema: boolean): void {
        this.setState({movieToUpdate: {id: movieId, inCinema}});
    }
}
