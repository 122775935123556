import './_loginPage.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {Router} from '@mp/route';
import classnames from 'classnames';
import React from 'react';
import {Navigate} from 'react-router-dom';
import {isAdminOrLoggedUser} from '../../../global';
// @ts-expect-error todo check it
import favicon from '../images/favicon.ico';
import {loginService} from '../services/login.service';

const MP_LOGIN_PAGE: string = 'mp-login-page';

interface LoginPageState {
    loginValue: string;
    pwdValue: string;
    loginFailed: boolean;
    blockUi: boolean;
}

export class LoginPage extends React.Component<unknown, LoginPageState> {
    constructor(props: unknown) {
        super(props);

        this.state = {loginFailed: false, blockUi: false, loginValue: '', pwdValue: ''};
    }

    public render() {
        if (isAdminOrLoggedUser()) {
            return <Navigate to={Router.getUrlToHomePage()} />;
        }

        const {loginFailed, blockUi, loginValue, pwdValue} = this.state;
        return (
            <div className={classnames(MP_LOGIN_PAGE)}>
                <div className={`${MP_LOGIN_PAGE}-container`}>
                    <img src={favicon} width="64px" alt="mp" />
                    <div className={`${MP_LOGIN_PAGE}-form`}>
                        <div className={`${MP_LOGIN_PAGE}-form-row`}>
                            <TextInput
                                autoCapitalize="none"
                                placeholder="login"
                                onChange={(el) => this.setState({loginValue: el.target.value, loginFailed: false})}
                            />
                        </div>
                        <div className={`${MP_LOGIN_PAGE}-form-row`}>
                            <TextInput
                                isPassword={true}
                                placeholder="hasło"
                                onChange={(el) => this.setState({pwdValue: el.target.value, loginFailed: false})}
                            />
                        </div>
                    </div>
                    {loginFailed && <div className={`${MP_LOGIN_PAGE}-login-error`}>Niepoprawny login lub hasło</div>}
                    <Button
                        title="Zaloguj"
                        disabled={blockUi || !this.hasInputsMinLength()}
                        onClick={() => {
                            this.setState({blockUi: true});
                            loginService({login: loginValue, password: pwdValue}).then(({success}) => {
                                this.setState({loginFailed: !success, blockUi: false}, () => {
                                    if (success) {
                                        window.location.reload();
                                    }
                                });
                            });
                        }}
                    />
                </div>
            </div>
        );
    }

    private hasInputsMinLength(): boolean {
        return this.state.loginValue.length >= 4 && this.state.pwdValue.length >= 5;
    }
}
