export const IKNOW_CARDS_NUMBER: number = 400;

export enum IKnowCategory {
    PEOPLE = 'people',
    WORLD = 'world',
    HISTORY = 'history',
    CULTURE = 'culture'
}

export enum View {
    Normal,
    PreviewCard
}

export interface IKnowCard {
    number: number;
    people: IKnowSubCard;
    world: IKnowSubCard;
    history: IKnowSubCard;
    culture: IKnowSubCard;
}

export interface IKnowSubCard {
    id: string;
    question: string;
    clue_1: string;
    clue_2: string;
    clue_3: string;
    answer: string;
}

export function resolveCategoryColor(category: IKnowCategory): string {
    switch (category) {
        case IKnowCategory.PEOPLE:
            return 'blue';
        case IKnowCategory.WORLD:
            return 'green';
        case IKnowCategory.HISTORY:
            return 'red';
        case IKnowCategory.CULTURE:
            return 'yellow';
        default:
            return 'unknown';
    }
}
