import './_dartsPage.tsx.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {useHideCalendarNearDates} from '@mp/common/hooks/useHideCalendarNearDates';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import {isEmpty} from '@mp/common/utils/array';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {DARTS_COUNT, MAX_PLAYERS} from '../consts';
import {DartScoreHistory} from '../types';
import {loadFromLocalStorage, saveInLocalStorage} from '../utils/localStorage';
import {DartsKeyBoard} from './DartsKeyBoard';
import {DartsScoreBoard} from './DartsScoreBoard';

export function DartsPage(): JSX.Element {
    const itemsRef: React.MutableRefObject<Array<HTMLInputElement>> = useRef<Array<HTMLInputElement>>([]);
    const [playersIds, setPlayersIds] = useState<Array<string>>([]);
    const [history, setHistory] = useState<DartScoreHistory>([]);
    const [selectedPlayerId, setSelectedPlayerId] = useState<string>(null);
    const [isVictory, setIsVictory] = useState<boolean>(false);
    const [questionModalVisible, setQuestionModalVisible] = useState<boolean>(false);

    useLayoutEffect(() => {
        const gameData = loadFromLocalStorage();
        if (gameData) {
            setPlayersIds(gameData.playersIds);
            setSelectedPlayerId(gameData.selectedPlayerId);
            setHistory(gameData.history);
            setIsVictory(gameData.isVictory);
        }
    }, []);

    useLayoutEffect(() => {
        setSelectedPlayerId(getSelectedPlayerId(history, playersIds));
        saveInLocalStorage({history, playersIds, isVictory, selectedPlayerId});
    }, [history.length, playersIds.length]);

    useHideCalendarNearDates();

    function handleStartGameClick(): void {
        const playerNames: Array<string> = Array.from(new Set(itemsRef.current.map(({value}): string => value))).filter(Boolean);
        if (isEmpty(playerNames)) {
            alert('No chyba nie...');
            return;
        }

        setPlayersIds(playerNames.map((value): string => value));
    }

    if (isEmpty(playersIds) || !selectedPlayerId) {
        return (
            <div className="darts-page-init">
                {new Array(MAX_PLAYERS).fill(null).map((_, i) => (
                    <TextInput
                        key={i}
                        autoCapitalize="on"
                        title={`Gracz ${i + 1}`}
                        reference={(element) => (itemsRef.current[i] = element)}
                    />
                ))}
                <Button title="Start" onClick={handleStartGameClick} />
            </div>
        );
    }

    function handleUndoClick(): void {
        setHistory(history.splice(0, history.length - 1));
    }

    return (
        <>
            {questionModalVisible && (
                <ConfirmModal
                    title="Reset gry"
                    question="Czy na pewno chcesz zresetować grę?"
                    handleConfirm={() => {
                        setPlayersIds([]);
                        setHistory([]);
                        setIsVictory(false);
                        setQuestionModalVisible(false);
                    }}
                    handleClose={() => setQuestionModalVisible(false)}
                />
            )}
            <div className="darts-page-game-progress">
                <DartsScoreBoard
                    playersIds={playersIds}
                    history={history}
                    selectedPlayerId={selectedPlayerId}
                    onVictory={() => setIsVictory(true)}
                />
                {isVictory ? (
                    <div className="end-game">
                        <Button
                            title="Cofnij"
                            onClick={() => {
                                handleUndoClick();
                                setIsVictory(false);
                            }}
                        />
                        <Button title="Reset" onClick={() => setQuestionModalVisible(true)} />
                    </div>
                ) : (
                    <DartsKeyBoard
                        onScoreClick={(score) => setHistory((entry) => [...entry, {id: selectedPlayerId, score}])}
                        onUndoClick={handleUndoClick}
                        onResetClick={() => setQuestionModalVisible(true)}
                    />
                )}
            </div>
        </>
    );
}

function getSelectedPlayerId(history: DartScoreHistory, playersIds: Array<string>): string {
    return playersIds[Math.floor((history.length % (DARTS_COUNT * playersIds.length)) / DARTS_COUNT)];
}
