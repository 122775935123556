import {CostEstimateItem} from '../types';

const COST_ESTIMATE_ITEM_MAP: Map<keyof CostEstimateItem, string> = new Map([
    ['price', 'Cena brutto'],
    ['pricePaid', 'Zapłacono brutto'],
    ['date', 'Data zakupu'],
    ['costRefund', 'Zwrot kosztów brutto'],
    ['img', 'Link do obrazka']
]);

export function getDescriptionByKey(key: keyof CostEstimateItem): string {
    return COST_ESTIMATE_ITEM_MAP.get(key) ?? null;
}
