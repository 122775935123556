import './_shoppingItemsListView.scss';
import {Button} from '@mp/common/components/button/ui/Button';
import {Modal} from '@mp/common/modals/Modal';
import {SvgButton, Icon} from '@mp/common/svg';
import {Svg} from '@mp/common/svg-icon';
import {isEmpty} from '@mp/common/utils/array';
import React from 'react';
import {updateListItemIsDoneService, updateItemCategoryService, updateListItemCountService} from '../services';
import {ShoppingListItem, ShoppingCategory, ItemCountModal} from '../types';

const SHOPPING_LIST_VIEW: string = 'shopping-list-view';
const ITEM_ROW: string = `${SHOPPING_LIST_VIEW}-row`;
const ITEM_ROW_CHECK: string = `${ITEM_ROW}-check`;
const ITEM_ROW_NAME: string = `${ITEM_ROW}-name`;
const ITEM_ROW_COUNT: string = `${ITEM_ROW}-count`;
const ITEM_ROW_CATEGORY: string = `${ITEM_ROW}-category`;

interface ShoppingItemsListProps {
    items: Array<ShoppingListItem>;
    categories: Array<ShoppingCategory>;
    onItemListUpdate: () => void;
}

interface ShoppingItemsListState {
    categoryModalVisible: boolean;
    itemCountModal: ItemCountModal;
    selectedItem: ShoppingListItem;
}

export class ShoppingItemsListView extends React.Component<ShoppingItemsListProps, ShoppingItemsListState> {
    constructor(props: ShoppingItemsListProps) {
        super(props);

        this.state = {
            categoryModalVisible: false,
            itemCountModal: null,
            selectedItem: null
        };

        this.onCheckChanged = this.onCheckChanged.bind(this);
        this.closeCategoryModal = this.closeCategoryModal.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.onCategorySelectChanged = this.onCategorySelectChanged.bind(this);
        this.updateItemCategory = this.updateItemCategory.bind(this);
    }

    render() {
        return (
            <div className={SHOPPING_LIST_VIEW}>
                {this.props.items.map((item) => this.renderListItem(item))}
                {this.renderChangeCategoryModal()}
                {this.renderChangeItemCountModal()}
            </div>
        );
    }

    private renderListItem(item: ShoppingListItem): JSX.Element {
        const rowClass: string = item.done ? `${ITEM_ROW}-done` : ITEM_ROW;
        return (
            <div key={item.id} className={rowClass}>
                <span className={ITEM_ROW_CHECK}>
                    <input type="checkbox" defaultChecked={item.done} onChange={(event) => this.onCheckChanged(item, event)} />
                </span>
                <span className={ITEM_ROW_NAME}>{item.name}</span>
                <span className={ITEM_ROW_COUNT} onClick={() => this.onItemCountClick(item)}>
                    {item.count}
                </span>
                <span className={ITEM_ROW_CATEGORY} onClick={() => this.onCategoryClick(item)}>
                    <i className="mp-svg-button">
                        <Svg name={item.icon} />
                    </i>
                </span>
            </div>
        );
    }

    private onItemCountClick(item: ShoppingListItem): void {
        this.setState({
            itemCountModal: {id: item.id, name: item.name, count: item.count}
        });
    }

    private renderChangeItemCountModal(): JSX.Element {
        const modalState: ItemCountModal = this.state.itemCountModal;
        const footer: JSX.Element = <Button title="Aktualizuj" onClick={() => this.updateItemCount(modalState)} />;

        if (modalState) {
            return (
                <Modal size="small" title={modalState.name} footer={footer} handleClose={() => this.setState({itemCountModal: null})}>
                    <div className="change-item-count-modal">
                        <SvgButton color="black" icon={Icon.Minus} onClick={() => this.countDec()} />
                        <span>{modalState.count}</span>
                        <SvgButton color="black" icon={Icon.Plus} onClick={() => this.countInc()} />
                    </div>
                </Modal>
            );
        }
    }

    private updateItemCount(item: ItemCountModal): void {
        updateListItemCountService(item)
            .then(() => this.props.onItemListUpdate())
            .then(() => this.setState({itemCountModal: null}));
    }

    private countInc(): void {
        const modalState: ItemCountModal = this.state.itemCountModal;
        modalState.count += 1;
        this.setState({itemCountModal: modalState});
    }

    private countDec(): void {
        const modalState: ItemCountModal = this.state.itemCountModal;
        if (modalState.count > 0) {
            modalState.count -= 1;
            this.setState({itemCountModal: modalState});
        }
    }

    private renderChangeCategoryModal(): JSX.Element {
        if (this.state.categoryModalVisible && !isEmpty(this.props.categories) && this.state.selectedItem) {
            const item: ShoppingListItem = this.state.selectedItem;

            return (
                <Modal
                    title={item.name}
                    footer={<Button title="Aktualizuj" onClick={() => this.updateItemCategory(item)} />}
                    handleClose={() => this.setState({categoryModalVisible: false})}
                >
                    <div className="change-category-modal">{this.renderCategories()}</div>
                </Modal>
            );
        }
    }

    private updateItemCategory(item: ShoppingListItem): void {
        updateItemCategoryService(item)
            .then(() => this.props.onItemListUpdate())
            .then(() => this.closeCategoryModal());
    }

    private renderCategories(): Array<JSX.Element> {
        return this.props.categories.map((category) => (
            <div
                key={category.id}
                onClick={() => this.onCategorySelectChanged(category.id)}
                className={`category-block${this.state.selectedItem.category_id === category.id ? '-selected' : ''}`}
            >
                <i className="mp-svg-button">
                    <Svg name={category.icon} />
                </i>
                <span className="category-block-name">{category.category}</span>
            </div>
        ));
    }

    private onCategorySelectChanged(categoryId: number) {
        const selectedItem: ShoppingListItem = this.state.selectedItem;
        selectedItem.category_id = categoryId;
        this.setState({selectedItem});
    }

    private onCategoryClick(item: ShoppingListItem) {
        this.setState((state) => ({
            categoryModalVisible: !state.categoryModalVisible,
            selectedItem: item
        }));
    }

    private closeCategoryModal() {
        this.setState({categoryModalVisible: false, selectedItem: null});
    }

    private onCheckChanged(item: ShoppingListItem, event: React.ChangeEvent<HTMLInputElement>) {
        const checked: boolean = event.currentTarget.checked;
        updateListItemIsDoneService(item, checked).then(() => this.props.onItemListUpdate());
    }
}
