import {ServiceResponseWithId} from '@mp/common/api';
import {ResultListItem} from '@mp/common/components/suggestion-list/types';
import {SuggestionList} from '@mp/common/components/suggestion-list/ui/SuggestionList';
import {getResultListItem} from '@mp/common/components/suggestion-list/utils/getResultListItem';
import {ConfirmModal} from '@mp/common/modals/ConfirmModal';
import React, {useState} from 'react';
import {addItemService, addItemToShoppingListService} from '../services';
import {ShoppingItem, ShoppingListItem} from '../types';

interface ShoppingItemsSearchProps {
    items: Array<ShoppingItem>;
    listItems: Array<ShoppingListItem>;
    maxSearchResult: number;
    onItemListUpdate: () => void;
}

export function ShoppingItemsSearch({items, listItems, maxSearchResult, onItemListUpdate}: ShoppingItemsSearchProps): JSX.Element {
    const [itemToAddToDataBase, setItemToAddToDataBase] = useState<ResultListItem>(null);

    return (
        <div className="shopping-suggestion-list">
            <SuggestionList
                items={items}
                highlightListItemFn={(item) => !!listItems?.find(({item_id: itemId}) => itemId === item.id)}
                maxSearchResult={maxSearchResult}
                onEnterClick={handleAddToDataBase}
                resetOnListItemClick={true}
                onListItemClick={(item) =>
                    addItemToShoppingListService(item.id, item.count).then(({success}) => success && onItemListUpdate())
                }
            />
            <AddToBaseModal />
        </div>
    );

    function handleAddToDataBase(nameWithCount: string): void {
        const listItem: ResultListItem = getResultListItem(nameWithCount);

        if (!items.find((item) => item.name === listItem.name)) {
            setItemToAddToDataBase(listItem);
        }
    }

    function AddToBaseModal(): JSX.Element {
        if (itemToAddToDataBase) {
            const {name, count} = itemToAddToDataBase;
            document.body.focus();
            return (
                <ConfirmModal
                    title="Dodawanie nowej rzeczy"
                    question={`Czy chcesz dodać: "${name}" do bazy?`}
                    handleConfirm={() =>
                        addItemService(name).then((response: ServiceResponseWithId) => {
                            if (response.success) {
                                addItemToShoppingListService(response.id, count)
                                    .then(onItemListUpdate)
                                    .then(() => setItemToAddToDataBase(null));
                            }
                        })
                    }
                    handleClose={() => setItemToAddToDataBase(null)}
                />
            );
        }
        return null;
    }
}
