import './_calendarNearDaysEvents.scss';
import {isNotEmpty} from '@mp/common/utils/array';
import {isNotEmptyString} from '@mp/common/utils/string';
import React from 'react';
import {CalendarDate} from '../types';
import {resolveCalendarEventsInNearestDays} from '../utils/getCalendarEventsIn';

export function CalendarNearDaysEvents(params: {calendarDates: Array<CalendarDate>}): JSX.Element {
    const eventElements: Array<JSX.Element> = [];
    resolveCalendarEventsInNearestDays(params.calendarDates).forEach(
        (value, key) => isNotEmptyString(value) && eventElements.push(renderEvent(key, value))
    );

    if (isNotEmpty(eventElements)) {
        return <div className="mp-near-events">{eventElements}</div>;
    }
    return null;

    function renderEvent(label: string, events: string): JSX.Element {
        const content: string = `${label}: ${events}`;
        return <div key={label}>{content}</div>;
    }
}
