import {createContext} from 'react';

export interface ICommonContext {
    showCalendarNearDates: boolean;
    isEditMode: boolean;
}

export const CommonContext = createContext({
    context: {
        showCalendarNearDates: true,
        isEditMode: false
    } as ICommonContext,
    // eslint-disable-next-line
    setContext: (context: ICommonContext): void => {}
});
