import {getVisibleDatesInGrid} from '@mp/common/components/calendar/utils/gridUtils';
import {Logger} from '@mp/common/logger';
import {firstElement, isNotEmpty, lastElement} from '@mp/common/utils/array';
import {switchGuard} from '@mp/common/utils/switchGuard';
import {CalendarDate, CalendarRepeatType, Grid, GridCell, GridDate} from './types';

export function resolveGrid(currentMonth: Date, calendarDates: Array<CalendarDate>): Grid {
    const performanceStart: number = performance.now();
    const gridDates: Array<GridDate> = getVisibleDatesInGrid(currentMonth, true);
    const firstGridDate: number = firstElement(gridDates).date.getTime();
    const lastGridDate: number = lastElement(gridDates).date.getTime();

    const calendarDatesInGridView: Array<CalendarDate> = [];
    calendarDates.forEach((calendarDate) => {
        const {repeat, date: dateFrom, dateTo} = calendarDate;
        switch (repeat) {
            case CalendarRepeatType.NONE: // EVERY_DAY
                if (dateTo == null) {
                    if (firstGridDate <= dateFrom.getTime() && lastGridDate >= dateFrom.getTime()) {
                        calendarDatesInGridView.push(calendarDate);
                    }
                } else {
                    if (
                        !(
                            (dateFrom.getTime() < firstGridDate && dateTo.getTime() < firstGridDate) ||
                            (dateFrom.getTime() > lastGridDate && dateTo.getTime() > lastGridDate)
                        )
                    ) {
                        let indexOfDateFromInGrid: number = null;
                        let indexOfDateToInGrid: number = null;
                        for (let i: number = 0; i < gridDates.length; i++) {
                            const grid = gridDates[i];
                            if (grid.date.getTime() === dateFrom.getTime()) {
                                indexOfDateFromInGrid = i;
                            }
                            if (grid.date.getTime() === dateTo.getTime()) {
                                indexOfDateToInGrid = i;
                                break;
                            }
                        }
                        const datesToAdd: Array<CalendarDate> = [];
                        const start: number = indexOfDateFromInGrid ?? 0;
                        const end: number = indexOfDateToInGrid ?? gridDates.length - 1;
                        for (let i: number = start; i <= end; i++) {
                            const dayOfWeek: number = calendarDate.date.getDay();
                            if (!calendarDate.everyWeek || gridDates[i].date.getDay() === dayOfWeek) {
                                datesToAdd.push({...calendarDate, date: gridDates[i].date});
                            }
                        }
                        calendarDatesInGridView.push(...datesToAdd);
                    }
                }
                break;
            case CalendarRepeatType.MONTH_EXACT_DAY:
                if (dateTo == null) {
                    const matchedDates: Array<Date> = gridDates
                        .filter((grid) => grid.date.getDate() === dateFrom.getDate())
                        ?.map((grid) => grid.date);
                    if (isNotEmpty(matchedDates)) {
                        calendarDatesInGridView.push(...matchedDates.map((matchedDate) => ({...calendarDate, date: matchedDate})));
                    }
                } else {
                    alert('Not implemented for repeat:MONTH_EXACT_DAY and dateTo:defined');
                }
                break;
            case CalendarRepeatType.YEAR:
                if (dateTo == null) {
                    const matchedDate: Date = gridDates.find(
                        (grid) => grid.date.getMonth() === dateFrom.getMonth() && grid.date.getDate() === dateFrom.getDate()
                    )?.date;
                    if (matchedDate) {
                        calendarDatesInGridView.push(calendarDate);
                    }
                } else {
                    alert('Not implemented for repeat:YEAR and dateTo:defined');
                }
                break;
            default:
                switchGuard(repeat);
        }
    });

    const result: Grid = gridDates.map((gridDate) => {
        const gridCell: GridCell = {
            ...gridDate,
            dates: calendarDatesInGridView
                .filter((d) => d.date.getMonth() === gridDate.date.getMonth() && d.date.getDate() === gridDate.date.getDate())
                .sort(compareTimeFn)
        };

        return gridCell;
    });
    const milliseconds: number = performance.now() - performanceStart;
    if (milliseconds > 10) {
        Logger.warn(`Low grid performance: ${milliseconds} ms`);
    }
    return result;
}

function compareTimeFn(a: CalendarDate, b: CalendarDate): number {
    const timeA: string = a?.timeFrom;
    const timeB: string = b?.timeFrom;
    if (timeA === timeB) {
        return 0;
    }
    if (timeA == null) {
        return 1;
    }
    if (timeB == null) {
        return -1;
    }
    return timeA.localeCompare(timeB);
}
