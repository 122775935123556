import {Logger} from '@mp/common/logger';
import {AxiosError, AxiosResponse} from 'axios';
import React from 'react';
import {createRoot, Root} from 'react-dom/client';
import {GLOBAL_ERROR_ID} from '../../consts';
import {Icon, SvgIcon} from '../../svg';
import {ErrorCode} from '../../types';
import {Button} from '../button/ui/Button';

const MP_BACKGROUND: string = 'mp-service-error-background';
const MP_WINDOW: string = 'mp-service-error-window';

interface ServiceErrorProps {
    error: AxiosError;
}

interface WithErrorMessage {
    message: string;
    errorMessage: string;
}

export class ServiceError extends React.Component<ServiceErrorProps> {
    constructor(props: ServiceErrorProps) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    public componentWillUnmount(): void {
        this.onClose();
    }

    public render() {
        let message: string = 'Wystąpił nieoczekiwany błąd!';

        if (this.props?.error) {
            const response: AxiosResponse<unknown> = this.props.error.response;
            const errorMessage: string = this.props.error.message;

            if (response) {
                if (response.status === ErrorCode.NotFound) {
                    message = `Nie znaleziono api ${response.config.url}`;
                } else if (response.status === ErrorCode.Unauthorised) {
                    message = 'Brak uprawnień!';
                } else if (response.status === ErrorCode.MethodNotAllowed) {
                    message = `Niedozwolona metoda: "${response.config.method}"`;
                } else if (response.data) {
                    if (typeof response.data === 'string') {
                        message = response.data;
                    } else {
                        const responseData: WithErrorMessage = response.data as WithErrorMessage;
                        message = responseData.message ?? responseData.errorMessage;
                    }
                }
            } else if (errorMessage) {
                message = errorMessage;
            }
        }

        return (
            <mp-service-error>
                <div className={MP_BACKGROUND} />
                <div className={MP_WINDOW}>
                    <div className={`${MP_WINDOW}-title`}>Error</div>
                    <div className={`${MP_WINDOW}-content`}>
                        <SvgIcon icon={Icon.Bomb} />
                        <div>{message}</div>
                    </div>
                    <div className={`${MP_WINDOW}-bottom`}>
                        <Button title="Zamknij" onClick={() => this.onClose()} />
                    </div>
                </div>
            </mp-service-error>
        );
    }

    private onClose(): void {
        unmountErrorRoot();
    }
}

export function insertServiceError(error: AxiosError): void {
    const errorElement: HTMLElement = getErrorElement();
    if (errorElement) {
        Logger.wholeError(error);
        getErrorRoot().render(<ServiceError error={error} />);
    } else {
        alert(JSON.stringify(error));
    }
}

let _root: Root;
function getErrorRoot(): Root {
    if (!_root) {
        _root = createRoot(getErrorElement());
    }
    return _root;
}

function unmountErrorRoot(): void {
    if (_root) {
        _root.unmount();
        _root = null;
    }
}

function getErrorElement(): HTMLElement {
    return document.getElementById(GLOBAL_ERROR_ID);
}
