import './_suggestionList.scss';
import {Key} from '@mp/common/types';
import {toLowerCaseAndReplacePolishChars} from '@mp/common/utils/string';
import React, {useRef, useState} from 'react';
import {firstElement} from '../../../utils/array';
import {Item} from '../types';
import {getResultListItem} from '../utils/getResultListItem';

const MP_SUGGESTION_LIST: string = 'mp-suggestion-list';
const SUGGESTION_LIST: string = 'suggestion-list';
const SUGGESTION_LIST_ITEM: string = 'suggestion-list-item';
const SUGGESTION_LIST_ITEM_COUNT: string = 'suggestion-list-item-count';

const DEFAULT_ITEM_COUNT: number = 1;

interface SuggestionListProps {
    items: Array<Item>;
    maxSearchResult: number;
    placeholder?: string;
    defaultSelectedId?: number;
    highlightListItemFn?: (item: Item) => boolean;
    onEnterClick?: (itemName: string) => void;
    onListItemClick: (item: Item) => void;
    resetOnListItemClick?: boolean;
}

export function SuggestionList({
    items,
    maxSearchResult,
    placeholder,
    defaultSelectedId,
    highlightListItemFn,
    onListItemClick,
    onEnterClick,
    resetOnListItemClick = false
}: SuggestionListProps): JSX.Element {
    const [filteredItems, setFilteredItems] = useState<Array<Item>>([]);
    const inputRef: React.MutableRefObject<HTMLInputElement> = useRef(null);

    return (
        <div className={MP_SUGGESTION_LIST}>
            <input
                defaultValue={items.find(({id}) => id === defaultSelectedId)?.name}
                type="text"
                autoCapitalize="none"
                placeholder={placeholder}
                spellCheck={true}
                onChange={(event) => onSearchTextChanged(event)}
                onKeyPress={(event) => onKeyPressed(event)}
                onFocus={(event) => onSearchTextChanged(event)}
                onBlur={() => setTimeout(() => setFilteredItems([]), 125)}
                ref={inputRef}
            />
            <div className={SUGGESTION_LIST}>{filteredItems.map((item) => renderListItem(item))}</div>
        </div>
    );

    function onSearchTextChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        const text: string = toLowerCaseAndReplacePolishChars(event.currentTarget.value.trim());
        if (text.trim() === '') {
            setFilteredItems([]);
        } else {
            const {words, count} = getResultListItem(text);
            const firstWord = firstElement(words);

            let _filteredItems: Array<Item> = Array.from(items);
            words.forEach((word) => {
                _filteredItems = _filteredItems.filter(({searchName}) => searchName.includes(word));
            });

            _filteredItems = _filteredItems.sort((a, b) => {
                if (a.searchName.startsWith(firstWord)) {
                    return -1;
                } else if (b.searchName.startsWith(firstWord)) {
                    return 1;
                }
                return a.searchName.localeCompare(b.searchName);
            });

            _filteredItems = _filteredItems.slice(0, maxSearchResult) || [];
            _filteredItems.forEach((item) => (item.count = count));

            setFilteredItems(_filteredItems);
        }
    }

    function renderListItem(item: Item): JSX.Element {
        let className: string = SUGGESTION_LIST_ITEM;

        let isOnList: boolean = false;
        if (highlightListItemFn && highlightListItemFn(item)) {
            className += '-on-list';
            isOnList = true;
        }
        return (
            <div key={item.id} className={className} onClick={() => onSearchResultItemClick(item, isOnList)}>
                <span>{item.name}</span>
                <span className={SUGGESTION_LIST_ITEM_COUNT}>{item.count || DEFAULT_ITEM_COUNT}</span>
            </div>
        );
    }

    function onSearchResultItemClick(item: Item, isOnList: boolean): void {
        if (!isOnList) {
            inputRef.current.focus();

            if (resetOnListItemClick) {
                inputRef.current.value = '';
            } else {
                inputRef.current.value = item.name;
            }

            onListItemClick(item);
            setFilteredItems([]);
        }
    }

    function onKeyPressed(event: React.KeyboardEvent<HTMLInputElement>): void {
        const keyCode: number = event.which || event.keyCode;
        const name: string = event.currentTarget.value.trim();
        if (onEnterClick && keyCode === Key.Enter && name?.length > 2) {
            onEnterClick(name);
        }
    }
}
