import {HOST} from '../../../global';
import {Router} from '../../../packages/route';
import {resolveMovieTimeId} from './resolveMovieTimeId';

const MESSENGER_PREFIX: string = `fb-messenger://share/?link=${HOST}`;

export function getShareLink(props: {date: Date; id: string; startTime?: string}): string {
    const {id: movieId, date, startTime} = props;
    const movieTimeId: string = resolveMovieTimeId({movieId, startTime});
    return `${MESSENGER_PREFIX}${Router.getUrlToCinemaProgramPage({date, id: movieTimeId ?? movieId})}`;
}
