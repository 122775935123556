import React from 'react';
import {Schedule} from '../types';
import {resolveMovieTimeId} from '../utils/resolveMovieTimeId';

export function ScheduleTile(props: {movieId: string; schedule: Schedule; onClick: (schedule: Schedule) => void}): JSX.Element {
    const {movieId, schedule, onClick} = props;
    const {startTime, versionTitle} = schedule;

    return (
        <button data-custom-stime={resolveMovieTimeId({movieId, startTime})} className="schedule-tile" onClick={() => onClick(schedule)}>
            <div className="schedule-tile-time">{startTime}</div>
            <div className="schedule-tile-version">{versionTitle}</div>
        </button>
    );
}
