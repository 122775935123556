import {Button} from '@mp/common/components/button/ui/Button';
import {DateInput} from '@mp/common/components/date-input/ui/DateInput';
import {TextInput} from '@mp/common/components/text-input/ui/TextInput';
import {getCurrentDate, getCurrentTime} from '@mp/common/utils/date';
import React from 'react';
import {t} from '../t.i18n';
import {Expense, ExpenseCategory} from '../types';

const MP_NUM_KEYBOARD = 'mp-num-keyboard';
const DOT_KEY: string = '.';
const RESET_KEY: string = 'CE';
const MAX_INPUT_LENGTH: number = 6;
const KEYS: Array<number | string> = [7, 8, 9, 4, 5, 6, 1, 2, 3, RESET_KEY, 0, DOT_KEY];
const DESCRIPTION_NEEDED_FOR_VALUE: number = 500;

interface NumKeyboardProps {
    expenseCategory: ExpenseCategory;
    onAddClick: (expense: Expense) => void;
}

interface NumKeyboardState {
    recalculated: boolean;
    isDescriptionNeeded: boolean;
}

export class NumKeyboard extends React.Component<NumKeyboardProps, NumKeyboardState> {
    private valueInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    private descriptionInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
    private dateInput: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    constructor(props: NumKeyboardProps) {
        super(props);
        this.keydownEventHandler = this.keydownEventHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            recalculated: false,
            isDescriptionNeeded: false
        };
    }

    public componentDidMount() {
        document.addEventListener('keydown', this.keydownEventHandler);
    }

    public componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownEventHandler);
    }

    private keydownEventHandler(event: KeyboardEvent): void {
        const {key} = event;

        if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)) {
            this.handleClick(key);
        } else if (key === ',') {
            this.handleClick(DOT_KEY);
        } else if (key === 'Delete') {
            this.handleClick(RESET_KEY);
        } else if (key === 'Enter') {
            this.onAddButtonClick();
        }
    }

    public get value(): number {
        return Number(this.valueInput.current.value);
    }

    public render() {
        const {expenseCategory} = this.props;

        return (
            <div className={MP_NUM_KEYBOARD}>
                <div className={`${MP_NUM_KEYBOARD}-category`}>{expenseCategory.name}</div>
                <DateInput reference={this.dateInput} defaultValue={getCurrentDate()} />
                <div className={`${MP_NUM_KEYBOARD}-top`}>
                    <TextInput style={{color: this.state.recalculated ? 'red' : 'black'}} reference={this.valueInput} disabled={true} />
                    <Button noMargin title="2/3" onClick={() => this.calculate2of3(this.valueInput.current.value)} />
                </div>
                <div className={`${MP_NUM_KEYBOARD}-numbers-container`}>{KEYS.map((key) => this.renderSquare(key))}</div>
                <div className={`${MP_NUM_KEYBOARD}-footer`}>
                    {this.state.isDescriptionNeeded && <TextInput placeholder={t.description} reference={this.descriptionInput} />}
                    <Button title={t.add} onClick={() => this.onAddButtonClick()} />
                </div>
            </div>
        );
    }

    private onAddButtonClick(): void {
        const {expenseCategory, onAddClick} = this.props;
        const description: string = this.descriptionInput?.current?.value;

        if (this.state.isDescriptionNeeded && description.length < 2) {
            return;
        }

        onAddClick({
            id: null,
            typeId: expenseCategory.id,
            value: this.value,
            date: `${this.dateInput.current.value} ${getCurrentTime()}`,
            description: description || null
        });
    }

    protected calculate2of3(value: string): void {
        if (!this.state.recalculated) {
            this.setState({recalculated: true});
            if (Number.parseFloat(value)) {
                value = ((Number(value) * 2) / 3).toFixed(2);
            }
        }
        this.valueInput.current.value = value;
    }

    private renderSquare(key: number | string): JSX.Element {
        return <Button title={key} key={key} onClick={() => this.handleClick(key)} />;
    }

    private handleClick(key: number | string): void {
        const inputValue: string = this.valueInput.current.value;
        if (key === RESET_KEY) {
            if (inputValue.length) {
                this.valueInput.current.value = '';
                this.setState({recalculated: false, isDescriptionNeeded: false});
            }
            return;
        }
        if (inputValue.length <= MAX_INPUT_LENGTH) {
            if (inputValue.includes(DOT_KEY)) {
                if (key === DOT_KEY) {
                    return;
                }
                if (inputValue.indexOf(DOT_KEY) === inputValue.length - 3) {
                    return;
                }
            }
            this.valueInput.current.value += key;
            this.setState({isDescriptionNeeded: Number(this.valueInput.current.value) >= DESCRIPTION_NEEDED_FOR_VALUE});
        }
    }
}
