import {Logger} from '@mp/common/logger';
import React, {ErrorInfo, GetDerivedStateFromError, PropsWithChildren} from 'react';

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
    constructor(props: PropsWithChildren) {
        super(props);
        this.state = {hasError: false};
        document.body.tabIndex = -1; // to be able to focus on body
    }

    static getDerivedStateFromError(error: GetDerivedStateFromError<object, ErrorBoundaryState>) {
        Logger.wholeError(error);
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        Logger.wholeError(error, errorInfo);
        // logErrorToMyService(error, errorInfo);
        this.setState({hasError: true});
    }
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}
