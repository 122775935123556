import './_recipeDescription.scss';
import {firstElement} from '@mp/common/utils/array';
import React from 'react';

const NEW_LINE_REGEXP = new RegExp(/(\r\n|\r|\n)/g);
const HEADING_START: string = '{';
const HEADING_END: string = '}';

export function RecipeDescription({description}: {description: string}): JSX.Element {
    if (!description) {
        return <div />;
    }

    const elements: Array<JSX.Element> = [];
    const parts: Array<string> = description
        .split(NEW_LINE_REGEXP)
        .filter((str) => !str.match(NEW_LINE_REGEXP))
        .map((str) => str.trim());

    if (!firstElement(parts).startsWith(HEADING_START)) {
        elements.push(<h3 key="default-heading">Opis</h3>);
    }

    parts.forEach((part, i) => {
        if (part.startsWith(HEADING_START) && part.endsWith(HEADING_END)) {
            elements.push(<h3 key={part}>{part.slice(1, -1)}</h3>);
        } else {
            elements.push(<div key={i}>{part}</div>);
        }
    });

    return <div className="recipe-description">{elements}</div>;
}
