import './_businessCardsPage.scss';
import {useHideCalendarNearDates} from '@mp/common/hooks/useHideCalendarNearDates';
import {Router} from '@mp/route';
import classNames from 'classnames';
import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {BusinessCardItem} from './types';

export const MP_BUSINESS_CARD_PAGE = 'mp-business-cards-page';

interface BusinessCarsPageProps {
    cardName: string;
}

export function BusinessCardsPage(): JSX.Element {
    const {name} = useParams<{name}>();
    useHideCalendarNearDates();
    return <BusinessCardsPageCC cardName={name} />;
}

class BusinessCardsPageCC extends React.Component<BusinessCarsPageProps> {
    private businessCards: Array<BusinessCardItem>;

    constructor(props: BusinessCarsPageProps) {
        super(props);

        const street: string = 'ul. Jarowa 5';
        const address: string = '80-217 Gdańsk';
        this.businessCards = [
            {name: 'codepol', street, address, nip: '957-103-79-46'},
            {name: 'powersys', street, address, nip: '957-012-47-78'}
        ];
    }

    public render() {
        return <div className={MP_BUSINESS_CARD_PAGE}>{this.renderView()}</div>;
    }

    private renderView(): JSX.Element {
        const {cardName} = this.props;

        if (cardName) {
            const item: BusinessCardItem = this.businessCards.find((cardItem: BusinessCardItem) => cardItem.name === cardName);
            return this.renderBusinessCard(item, true);
        }
        return <>{this.businessCards.map((cardItem: BusinessCardItem) => this.renderBusinessCard(cardItem))}</>;
    }

    private renderBusinessCard(cardItem: BusinessCardItem, isSingle?: boolean): JSX.Element {
        const {name, street, address, nip} = cardItem ?? {};
        const className: string = classNames('business-card', {'business-card-single': isSingle});
        return (
            <Link className={className} to={Router.getUrlToBusinessCardsPage(name)} key={name}>
                <div className="name">{name?.toUpperCase()}</div>
                {isSingle && (
                    <>
                        <div>{street}</div>
                        <div>{address}</div>
                        <div>{nip}</div>
                    </>
                )}
            </Link>
        );
    }
}
