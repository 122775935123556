import {service, ServiceResponseWithId} from '@mp/common/api';
import {toDate} from '@mp/common/utils/converters';
import {Invoice} from '../types';

const INVOICES_SERVICE_NAME: string = 'invoices';

export function loadInvoicesService(): Promise<Array<Invoice>> {
    return service.get<Array<Invoice>>(INVOICES_SERVICE_NAME).then(({data}) => resolveInvoices(data));
}

export function addInvoiceService(data: Invoice): Promise<ServiceResponseWithId> {
    return service.post(INVOICES_SERVICE_NAME, {data});
}

function resolveInvoices(rawInvoices: Array<Invoice>): Array<Invoice> {
    return rawInvoices.map((raw) => ({
        ...raw,
        dateFrom: toDate(raw.dateFrom as unknown as string),
        dateTo: toDate(raw.dateTo as unknown as string)
    }));
}
