import {service} from '@mp/common/api';
import {AxiosResponse} from 'axios';
import {IKnowCard} from '../types';

interface IKnowCardDTO {
    number: string;
    people: IKnowSubCardDTO;
    world: IKnowSubCardDTO;
    history: IKnowSubCardDTO;
    culture: IKnowSubCardDTO;
}

interface IKnowSubCardDTO {
    id: string;
    question: string;
    clue_1: string;
    clue_2: string;
    clue_3: string;
    answer: string;
}

export function loadIKnowCardsService(): Promise<Array<IKnowCard>> {
    return service
        .get<Array<IKnowCardDTO>>('games.IKnowCardsLoad')
        .then((result: AxiosResponse<Array<IKnowCardDTO>>) => resolveIKnowCards(result.data));
}

function resolveIKnowCards(rawCards: Array<IKnowCardDTO>): Array<IKnowCard> {
    return rawCards.map((raw) => ({
        number: Number(raw.number),
        world: raw.world,
        culture: raw.culture,
        history: raw.history,
        people: raw.people
    }));
}
