import {SvgIcon} from '@mp/common/svg';
import {Router} from '@mp/route';
import React from 'react';
import {Link} from 'react-router-dom';
import {Category, View} from '../types';
import {getDarkColor, getLightColor} from '../utils/colors';

export function CategoriesListView(props: {categories: Array<Category>}): JSX.Element {
    const {categories} = props;

    return (
        <div className="bucket-list-categories">
            {categories.map((category: Category) => (
                <CategoryBox key={category.id} category={category} />
            ))}
        </div>
    );
}

export function CategoryBox(params: {category: Category}): JSX.Element {
    const {category} = params;
    const {id, name, icon, items} = category;

    return (
        <Link to={Router.getUrlToBucketListPage({categoryId: id, view: View.NOT_COMPLETED_ITEMS_LIST, editItemId: null})}>
            <div key={id} className="box" style={{backgroundColor: getLightColor(id)}}>
                <span className="icon">
                    <SvgIcon icon={icon} color={getDarkColor(id)} />
                </span>
                <span
                    className="title"
                    style={{backgroundColor: getDarkColor(id)}}
                >{`${name} (${items.uncompleted.length} | ${items.completed.length})`}</span>
            </div>
        </Link>
    );
}
