import './_spicesPage.scss';
import {Router} from '@mp/route';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Loading} from '../../../components/loading';
import {loadSpicesService} from '../services/spicesServices';
import {Spice} from '../types';

export function SpicesPage(): JSX.Element {
    const [spices, setSpices] = useState<Array<Spice>>(null);

    useEffect(() => {
        loadSpicesService().then(setSpices);
    }, []);

    if (!spices) {
        return <Loading />;
    }

    return <div className="mp-spices-page">{spices.map(SpiceBox)}</div>;
}

function SpiceBox(spice: Spice): JSX.Element {
    const getOverdueClass = (): string => {
        if (spice.overdue > 0) {
            return 'overdue';
        } else if (spice.overdue > -30) {
            return 'near-overdue';
        }
        return null;
    };
    const overdueClass: string = getOverdueClass();

    return (
        <Link key={spice.id} to={Router.getUrlToChecklistPage({list: 10, item: spice.id})}>
            <div className={classNames('spice', {[overdueClass]: !!overdueClass})}>
                <div className="spice-inner">
                    <div>{spice.name}</div>
                    <span>{spice.deadline}</span>
                </div>
            </div>
        </Link>
    );
}
